// Chakra imports
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Text,
    Textarea,
    useColorModeValue
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import * as React from "react";
import {MdCached, MdOutlineSaveAs} from "react-icons/md";
import {SiGoogle, SiHomeassistantcommunitystore, SiLinkedin} from "react-icons/si";
import {PiUserCirclePlus} from "react-icons/pi";
import {GlobeIcon} from "../../../../components/icons/Icons";


export default function CompanyUserSocial(props: {
    dataFunc: any;
    saveFunc: any;
    loading: boolean;
    error: string;
    user: any;
    allowControl: boolean;
    setResponse: React.Dispatch<any>,
    [x: string]: any;
}) {

    const {
        dataFunc,
        saveFunc,
        loading,
        error,
        user,
        allowControl,
        setResponse,
        ...rest
    } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.700', 'white');
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'});

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const {id, value} = event.target;
        setResponse({
            ...user,
            [id]: value,
        })
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {id, value} = event.target;
        setResponse({
            ...user,
            [id]: value?.trim()
        })
    }

    return (
        <Card mb={{base: '0px', lg: '20px'}} alignItems='center' {...rest}>
            <Flex
                align={{sm: "flex-start", lg: "center"}}
                justify='space-between'
                w='100%'
                px='22px'
                pb='20px'
                mb='10px'
                boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
                <Text color={textColorSecondary} fontSize='xl' fontWeight='600'>
                    Social
                </Text>
                {allowControl && <Stack direction={['column', 'row']}>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        isLoading={loading}
                        onClick={saveFunc}>
                        {!loading && <Icon as={MdOutlineSaveAs} color={iconColor} w='24px' h='24px'/>}
                    </Button>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        isLoading={loading}
                        onClick={dataFunc}>
                        {!loading && <Icon as={MdCached} color={iconColor} w='24px' h='24px'/>}
                    </Button>
                </Stack>}
            </Flex>
            <Flex flexDirection='column' width={'100%'}>
                <Flex width={'100%'} mb="20px">
                    <Icon as={PiUserCirclePlus} h={'24px'} w={'24px'}/>
                    <FormControl>
                        <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                                   htmlFor="iconPhoto" ms="10px">
                            User Photo URL
                        </FormLabel>
                        <Textarea id="iconPhoto"
                                  placeholder="Put user's icon photo URL from Internet, Facebook, Linked-In etc"
                                  value={user.iconPhoto || ""} color={textColorPrimary}
                                  onBlur={handleBlur}
                                  onChange={handleChange}/>
                    </FormControl>
                </Flex>
                <Flex width={'100%'} mb="20px">
                    <Icon as={SiGoogle} h={'18px'} w={'18px'}/>
                    <FormControl>

                        <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                                   htmlFor="googleProfile" ms="10px">
                            Google Profile URL
                        </FormLabel>
                        <Textarea id="googleProfile" placeholder="www.google.com/profiles/john-william"
                                  value={user.googleProfile || ""} color={textColorPrimary}
                                  onBlur={handleBlur}
                                  onChange={handleChange}/>
                    </FormControl>
                </Flex>
                <Flex width={'100%'} mb="20px">
                    <Icon as={SiLinkedin} h={'18px'} w={'18px'}/>
                    <FormControl>
                        <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                                   htmlFor="linkedinProfile" ms="10px">
                            Linked-In Profile URL
                        </FormLabel>
                        <Textarea id="linkedinProfile" placeholder="www.linkedin.com/in/john-william-1234"
                                  value={user.linkedinProfile || ""} color={textColorPrimary}
                                  onBlur={handleBlur}
                                  onChange={handleChange}/>
                    </FormControl>
                </Flex>
                <Flex width={'100%'}>
                    <Icon as={SiHomeassistantcommunitystore} h={'18px'} w={'18px'}/>
                    <FormControl>
                        <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                                   htmlFor="address" ms="10px">
                            Address
                        </FormLabel>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<GlobeIcon color='gray.300'/>}
                            />
                            <Input id="address" placeholder="CLAIRE TONKIN, PO BOX 752, SYDNEY NSW 2108, AUSTRALIA"
                                   value={user.address || ""} type={"text"} color={textColorPrimary}
                                   onBlur={handleBlur}
                                   onChange={handleChange}/>
                        </InputGroup>
                    </FormControl>
                </Flex>
            </Flex>
        </Card>
    )
}

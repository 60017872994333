import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Text
} from "@chakra-ui/react";
import React from "react";

export default function CompanyUsersDeleteAlert(props: {
                                                       podcastIds: number[];
                                                       deleteFunc: any;
                                                       isOpen: boolean;
                                                       onOpen: any;
                                                       onClose: any;
                                                   }
) {
    const {
        podcastIds,
        deleteFunc,
        isOpen,
        onOpen,
        onClose
    } = props;
    {


        const cancelRef = React.useRef()

        return (
            <>
                <AlertDialog
                    motionPreset='slideInBottom'
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                    isOpen={isOpen}
                    isCentered
                >
                    <AlertDialogOverlay/>

                    <AlertDialogContent>
                        <AlertDialogHeader>Delete Users?</AlertDialogHeader>
                        <AlertDialogCloseButton/>
                        <AlertDialogBody>
                            <Text
                                align={"center"}
                                color={"red"}
                                fontSize='md'
                                fontWeight='500'>
                                Deleting {podcastIds.length} Users
                            </Text>
                            <Text
                                align={"center"}
                                fontSize='md'
                                fontWeight='400'>
                                Are you sure you want to delete permanently selected users with media files
                                stored?
                            </Text>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                No
                            </Button>
                            <Button colorScheme='red' ml={3} onClick={deleteFunc}>
                                Yes
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </>
        )
    }
}
// Chakra imports
import {Box, SimpleGrid, useColorModeValue} from '@chakra-ui/react';
// Custom components
import Information from 'views/admin/profile/components/Information';
import {DateTime} from "luxon";

// Assets
export default function CompanyUserGeneralInformation(props: {
                                                          id: number;
                                                          version: number;
                                                          companyId: number;
                                                          cognitoUserId: string;
                                                          sub: string;
                                                          modifiedDate: string;
                                                          lastLoginDate: string;
                                                          [x: string]: any
                                                      }
) {
    const {
        id,
        version,
        companyId,
        cognitoUserId,
        sub,
        modifiedDate,
        lastLoginDate,
        ...rest
    } = props;

    let defaultValue = "---";
// Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'gray.400';
    const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
    return (
        <Box mb={{base: '0px', '2xl': '20px'}}>
            <SimpleGrid columns={{base: 1, md: 2, lg: 5, '2xl': 5}} gap='20px' mb='20px'>
                <Information boxShadow={cardShadow} title='Cognito Username'
                             value={cognitoUserId ? cognitoUserId : defaultValue}/>
                <Information boxShadow={cardShadow} title='Cognito SUB' value={sub ? sub : defaultValue}/>
                <Information boxShadow={cardShadow} title='User/Company/Version'
                             value={id ? id + " / " + companyId + " / " + version : defaultValue}/>
                <Information boxShadow={cardShadow} title='Modified Date'
                             value={modifiedDate ? DateTime.fromISO(modifiedDate).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : defaultValue}/>
                <Information boxShadow={cardShadow} title='Last Activity Date'
                             value={lastLoginDate ? DateTime.fromISO(lastLoginDate).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : defaultValue}/>
            </SimpleGrid>
        </Box>
    );
}

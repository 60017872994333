import {Box, Grid, useToast} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import useAxiosFunction from "../../../../hooks/useAxiosFunction";
import axios from "../../../../api/BackendRest";
import CompanyUserBanner from "./CompanyUserBanner";
import banner from 'assets/img/auth/banner.png';
import CompanyUserGeneralInformation from "./CompanyUserGeneral";
import CompanyUserPrivate from "./CompanyUserPrivate";
import CompanyUserSocial from "./CompanyUserSocial";
import CompanyUserTopics from "./CompanyUserTopics";
import {TOAST_DURATION_MS} from "../../../../api/Constants";
import {
    deleteUserSuccessMessage,
    isDeleted,
    modifiedUserSuccessMessage,
    newPodcastUserErrorMessage,
    newPodcastUserSuccessMessage
} from "../../common/Validation";
import useCognitoGroups from "../../../../hooks/cognitoGroupsFunction";
import useStatelessAxiosFunction from "../../../../hooks/useStatelessAxiosFunction";

interface PageRouteParams {
    userID: string
}


const CompanyUserForm: React.FC = (): JSX.Element => {
    const params: PageRouteParams = useParams<PageRouteParams>();
    const toast = useToast()
    const [statelessError, , statelessLoading, statelessAxiosFetch] = useStatelessAxiosFunction();
    const [response, setResponse, error, , loading, axiosFetch] = useAxiosFunction({});
    const [version, setVersion] = useState<number>()
    const [podcastCount, setPodcastCount] = useState<number>(0)
    const [isAdmin, isConsoleAdmin,] = useCognitoGroups(false);
    const history = useHistory();

    useEffect(() => {
        isConsoleAdmin();
        getData();
    }, [])

    useEffect(() => {
        if (isDeleted(response)) {
            toast({
                title: deleteUserSuccessMessage(response),
                duration: TOAST_DURATION_MS,
                status: 'info',
                isClosable: true,
            });
            history.push('/admin/users');
        }
    }, [response?.status])

    useEffect(() => {
        if ((version !== undefined)
            && response?.version && (version < response.version)
            && !isDeleted(response))
            toast({
                title: modifiedUserSuccessMessage(response, version, response.version),
                duration: TOAST_DURATION_MS,
                status: 'success',
                isClosable: true,
            });
        setVersion(prev => {
            return response.version
        })
    }, [response?.version]);

    const getData = () => {
        axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: `/users/${params.userID}`
        });
        statelessAxiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: `/podcasts/count/user/${params.userID}`,
        }).then(value => {
            setPodcastCount(value?.data);
        })
    }

    const newPodcast = () => {
        statelessAxiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: `/podcasts/new/${params.userID}`,
        }).then(value => {
            if (value.status === 202 || value.status === 200) {
                toast({
                    title: newPodcastUserSuccessMessage(response, value),
                    duration: TOAST_DURATION_MS,
                    status: 'success',
                    isClosable: true,
                });
            } else {
                toast({
                    title: newPodcastUserErrorMessage(response, value),
                    duration: TOAST_DURATION_MS,
                    status: 'error',
                    isClosable: true,
                });
            }
        })
    }

    const saveData = () => {
        axiosFetch({
            axiosInstance: axios,
            method: 'put',
            url: '/users',
            body: response
        });
    }

    const deleteData = () => {
        axiosFetch({
            axiosInstance: axios,
            method: 'delete',
            url: `/users/${params.userID}`
        });
    }

    const isLoading = loading || statelessLoading;
    const isError = error || statelessError;

    return (
        <Box pt={{base: '130px', md: '80px', xl: '80px'}}>

            <CompanyUserGeneralInformation id={response.id}
                                           version={response.version}
                                           cognitoUserId={response.cognitoUserId}
                                           companyId={response.companyId}
                                           sub={response.sub}
                                           lastLoginDate={response.lastLoginDate}
                                           modifiedDate={response.modifiedDate}
            />
            <Grid templateColumns={{base: '1fr', lg: '1fr'}} gap={{base: '20px', xl: '0px'}}>
                <Grid templateColumns={{base: '1fr', lg: 'repeat(2, 1fr)'}} gap={'20px'}>
                    <CompanyUserBanner id={response.id}
                                       error={isError}
                                       dataFunc={getData}
                                       saveFunc={saveData}
                                       deleteFunc={deleteData}
                                       newPodcastFunc={newPodcast}
                                       loading={isLoading}
                                       canModify={isAdmin}
                                       banner={banner}
                                       job={response.title}
                                       status={response.status}
                                       avatar={response.iconPhoto}
                                       lastName={response.lastName}
                                       firstName={response.firstName}
                                       email={response.email}
                                       cognitoUserId={response.cognitoUserId}
                                       podcastCount={podcastCount}
                                       topics={response.topics}
                    />

                    <CompanyUserTopics dataFunc={getData}
                                       saveFunc={saveData}
                                       setResponse={setResponse}
                                       error={isError}
                                       loading={isLoading}
                                       user={response}
                                       allowControl={false}
                    />
                </Grid>
                <Grid templateColumns={{base: '1fr', lg: 'repeat(2, 1fr)'}} gap={'20px'}>
                    <CompanyUserPrivate dataFunc={getData}
                                        saveFunc={saveData}
                                        setResponse={setResponse}
                                        error={isError}
                                        loading={isLoading}
                                        user={response}
                                        editEmail={false}
                                        allowControl={false}
                    />
                    <CompanyUserSocial dataFunc={getData}
                                       saveFunc={saveData}
                                       loading={isLoading}
                                       error={isError}
                                       user={response}
                                       allowControl={false}
                                       setResponse={setResponse}/>
                </Grid>
            </Grid>
        </Box>
    );
}

export default CompanyUserForm
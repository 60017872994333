import {Box, useToast} from "@chakra-ui/react";
import React, {useEffect} from "react";
import useAxiosFunction from "../../../../hooks/useAxiosFunction";
import {defaultNewTopic, TOAST_DURATION_MS} from "../../../../api/Constants";
import {useHistory} from "react-router-dom";
import {newTopicSuccessMessage, validateField, ValidationResult} from "../../common/Validation";
import axios from "../../../../api/BackendRest";
import CompanyTopicDetails from "./CompanyTopicDetails";
import useCognitoGroups from "../../../../hooks/cognitoGroupsFunction";


const CompanyNewTopicForm: React.FC = (): JSX.Element => {
    const [response, setResponse, error, setError, loading, axiosFetch] = useAxiosFunction(defaultNewTopic);
    const [isAdmin, isConsoleAdmin,] = useCognitoGroups(false);
    const history = useHistory();
    const toast = useToast()

    useEffect(() => {
        isConsoleAdmin();
    }, [])

    useEffect(() => {
        if (response?.id) {
            toast({
                title: newTopicSuccessMessage(response),
                duration: TOAST_DURATION_MS,
                status: 'success',
                isClosable: true,
            });
            history.push('/admin/topics');
        }
    }, [response?.id])

    const clear = () => {
        setResponse(defaultNewTopic)
        setError(null)
    }

    const deleteTopic = () => {
        history.push('/admin/topics')
    }

    const validate = (name: string, value: string, validation: (name: string, value: string) => ValidationResult): boolean => {
        const result = validation(name, value);
        if (!result.isValid)
            toast({
                title: result.message,
                status: 'error',
                isClosable: true,
            })
        return result.isValid;
    }

    const saveTopic = () => {
        if (validate("Title", response.title, validateField))
            axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/topics',
                body: response
            });
    }

    return (
        <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
            {/* Main Fields */}
            <CompanyTopicDetails dataFunc={clear}
                                 saveFunc={saveTopic}
                                 deleteFunc={deleteTopic}
                                 canModify={isAdmin}
                                 loading={loading}
                                 error={error}
                                 topic={response}
                                 allowControl={true}
                                 setResponse={setResponse}/>
        </Box>
    );
}

export default CompanyNewTopicForm
import {
    Avatar,
    Box,
    Button,
    Center,
    Checkbox,
    Flex,
    Grid,
    Icon,
    Link,
    SkeletonCircle,
    SkeletonText,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import {useEffect, useRef} from 'react';
import {MdCached, MdOutlinePersonRemove} from "react-icons/md";
import useAxiosFunction from "../../../../hooks/useAxiosFunction";
import axios, {getMultipleParams, getSorting} from "../../../../api/BackendRest";
import {DateTime} from "luxon";
import ems from 'format-duration';
import {defaultPageSize, defaultUserFilter, TOAST_DURATION_MS} from "../../../../api/Constants";
import useCognitoGroups from "../../../../hooks/cognitoGroupsFunction";
import SimplePager from "../../../../components/pager/SimplePager";
import SimpleFilter from "../../../../components/filter/SimpleFilter";
import useStatelessAxiosFunction from "../../../../hooks/useStatelessAxiosFunction";
import CompanyPodcastsDeleteAlert from "./CompanyPodcastsDeleteAlert";

type RowObj = {
    id: number,
    userId: number,
    companyId: number,
    firstName: string;
    lastName: string;
    email: string;
    status: string;
    iconPhoto: string;
    title: string;
    durationMs: number;
    listeningTime: number;
    fileLocation: string;
    createDate: string;
    modifiedDate: string;
    comment: string;
    articleCount: number;
};

const defaultSortingSate = [{
    "id": "id",
    "desc": true
}]

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function CompanyPodcastsTable() {

    const [sorting, setSorting] = React.useState<SortingState>(defaultSortingSate);
    const [deletingPodcasts, setDeletingPodcasts] = React.useState<number[]>([]);

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'});

    const [response, , stateFullError, setStateFullError, stateFullLoading, axiosFetch] = useAxiosFunction({});
    const [statelessError, setStatelessError, statelessLoading, statelessAxiosFetch] = useStatelessAxiosFunction();

    const [isAdmin, isConsoleAdmin,] = useCognitoGroups(false);
    const [pageSize, setPageSize] = React.useState<number>(defaultPageSize[0]);

    const filterRef: React.MutableRefObject<HTMLSelectElement> = useRef<HTMLSelectElement>();
    const filterValueRef: React.MutableRefObject<HTMLInputElement> = useRef<HTMLInputElement>();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()

    let loading = stateFullLoading || statelessLoading
    let error = stateFullError || statelessError

    const getData = (page: number = 0) => {
        if (response?.content)
            response.content = null

        setStateFullError(null)
        setStatelessError(null)

        axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: `/podcasts/pages?page=${page}&size=${pageSize}&${filterRef.current.value}=${filterValueRef.current.value}&${getSorting(sorting)}`
        });
    }

    const getResetData = () => {
        filterValueRef.current.value = null;
        filterRef.current.value = defaultUserFilter;

        setDeletingPodcasts([])
        setSorting(defaultSortingSate);
        getData();
    }

    useEffect(() => {
        isConsoleAdmin();
        getData();
    }, [pageSize, sorting])

    const updateDeletedPodcasts = (num: number) => {
        if (!deletingPodcasts.includes(num)) {
            setDeletingPodcasts(prev => prev.concat(num))
        } else {
            setDeletingPodcasts(prev => prev.filter(value => value !== num))
        }
    }

    const deletePodcastsFunc = () => {
        onClose()
        statelessAxiosFetch({
            axiosInstance: axios,
            method: 'delete',
            url: `/podcasts/purge?${getMultipleParams(deletingPodcasts, "id")}`,
        }).then(value => {
            if (value?.status === 200) {
                toast({
                    title: `Successfully permanently deleted ${deletingPodcasts.length} podcasts with all stored media content!`,
                    duration: TOAST_DURATION_MS,
                    status: 'success',
                    isClosable: true,
                });
                getResetData();
            } else {
                toast({
                    title: `Error encountered while deleting ${deletingPodcasts.length} podcasts, error details: ${value?.statusText}`,
                    duration: TOAST_DURATION_MS,
                    status: 'error',
                    isClosable: true,
                });
            }
        })
    }


    let content = response?.content ? response.content : []
    let defaultData = content as [];

    const columns = [
        columnHelper.display({
            id: 'rowNumber',
            cell: ({row, table}) => (
                <Flex align='center'>
                    <Text
                        color={textColorSecondary}
                        fontSize='sm'
                        fontWeight='600'>
                        {(table?.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1 + response?.pageable?.offset}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    ID
                </Text>
            ),
            cell: (info) => (
                <Flex align='center'>
                    <Text
                        color={textColorSecondary}
                        fontSize='sm'
                        fontWeight='600'>
                        {info.getValue()}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('firstName', {
            id: 'first_name',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    USER
                </Text>
            ),
            cell: ({row}) => (
                <Flex align='center'>
                    <Avatar
                        src={row.original.iconPhoto}
                        w='40px'
                        h='40px'
                        me='8px'
                    />
                    <Box bg={"transparent"} alignContent={"flex-start"} justifyContent={"flex-start"}>
                    <Text
                        color={textColorSecondary}
                        fontSize='sm'
                        fontWeight='600'>
                        {row.original.firstName}
                    </Text>
                        {row.original.status === 'DELETED' && <Text
                        color={"red"}
                        fontSize='sm'
                        fontWeight='600'>
                        {row.original.status}
                    </Text>}
                    </Box>
                </Flex>
            )
        }),
        columnHelper.accessor('email', {
            id: 'email',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    EMAIL
                </Text>
            ),
            cell: (info) => (
                <Text
                    color={textColor}
                    fontSize='sm'
                    fontWeight='500'>
                    {info.getValue()}
                </Text>
            )
        }),
        columnHelper.accessor('title', {
            id: 'title',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    LINK
                </Text>
            ),
            cell: (info) => (
                <Link
                    href={info.row.original.fileLocation}
                    isExternal
                    color={textColor}
                    _hover={{ color: "red",textDecoration: "underline" }}
                    fontSize='sm'
                    fontWeight='500'>
                    {info.getValue()}
                </Link>
            )
        }),
        columnHelper.accessor('articleCount', {
            id: 'article_count',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    CHAPTERS
                </Text>
            ),
            cell: (info) => (
                <Text
                    color={textColorSecondary}
                    align={"right"}
                    fontSize='sm'
                    fontWeight='600'>
                    {info.getValue()}
                </Text>
            )
        }),
        columnHelper.accessor('durationMs', {
            id: 'duration_ms',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    DURATION
                </Text>
            ),
            cell: (info) => (
                <Text
                    color={textColor}
                    align={"right"}
                    fontSize='sm'
                    fontWeight='500'>
                    {ems(info.getValue(), {leading: true, ms: true})}
                </Text>
            )
        }),
        columnHelper.accessor('createDate', {
            id: 'create_date',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    CREATED
                </Text>
            ),
            cell: (info) => (
                <Box bg={"transparent"} alignContent={"flex-start"} justifyContent={"flex-start"}>
                    <Text
                        color={textColor}
                        fontSize='sm'
                        fontWeight='500'>
                        {info.getValue() && DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_SHORT)}
                    </Text>
                    <Text
                        color={textColor}
                        fontSize='sm'
                        fontWeight='500'>
                        {info.getValue() && DateTime.fromISO(info.getValue()).toLocaleString(DateTime.TIME_24_SIMPLE)}
                    </Text>
                </Box>
            )
        }),
        columnHelper.display({
            id: "select",
            enableSorting: false,
            cell: ({row}) => (
                <Checkbox id={`select_${row.original.id}`}
                          colorScheme='brand'
                          disabled={loading}
                          isChecked={deletingPodcasts.includes(row.original.id) || false}
                          onChange={(event) => updateDeletedPodcasts(row.original.id)}
                >
                </Checkbox>
            )
        })
    ];
    const data = [...defaultData];
    const table = useReactTable({
        data,
        columns,
        state: {
            //columnVisibility,
            sorting,
        },
        enableColumnResizing: true,
        manualSorting: true,
        //onColumnVisibilityChange: setColumnVisibility,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    return (
        <Flex
            direction='column'
            w='100%'
            overflowX={{sm: "scroll", lg: "hidden"}}>
            <Flex
                align={{sm: "flex-start", lg: "center"}}
                justify='space-between'
                w='100%'
                px='22px'
                pb='20px'
                mb='10px'
                boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
                <Grid templateColumns={{base: '1fr', md: 'repeat(2, 1fr)'}} w={'100%'} gap={'10px'}>
                    <Grid templateColumns={{base: '1fr', "2xl": 'repeat(2, 1fr)'}}
                          gap={'10px'} w={'min-content'}>
                        <SimpleFilter filterRef={filterRef}
                                      filterValueRef={filterValueRef}
                                      loading={loading}
                                      dataFunc={getData}
                                      mr={{lg: "20px", md: "0px"}}
                                      justifyContent={{base: "left", lg: "left", md: "right", sm: "right"}}>
                            <option itemID={`nameFilter`} key={'firstName'} id={`firstName`}
                                    value={'firstName'}>First Name
                            </option>
                            <option itemID={`lastNameFilter`} key={'lastName'} id={`lastName`}
                                    value={'lastName'}>Last Name
                            </option>
                            <option itemID={`emailFilter`} key={'email'} id={`email`}
                                    value={'email'}>E-Mail
                            </option>
                            <option itemID={`statusFilter`} key={'status'} id={`status`}
                                    value={'status'}>User Status
                            </option>
                            <option itemID={`topicNameFilter`} key={'topicName'} id={`topicName`}
                                    value={'topicName'}>Topics
                            </option>
                        </SimpleFilter>
                        <SimplePager dataFunc={getData}
                                     loading={loading}
                                     pageSize={pageSize}
                                     response={response}
                                     setPageSize={setPageSize}
                                     justifyContent={{base: "left", lg: "left", md: "right", sm: "right"}}
                        />
                    </Grid>
                    <Stack direction={['column', 'row']} justifyContent={"right"} w={'100%'}>
                        <Button
                            alignItems='center'
                            justifyContent='center'
                            bg={bgButton}
                            _hover={bgHover}
                            _focus={bgFocus}
                            _active={bgFocus}
                            w='37px'
                            h='37px'
                            lineHeight='100%'
                            borderRadius='10px'
                            isLoading={loading}
                            disabled={!isAdmin || deletingPodcasts.length === 0}
                            onClick={onOpen}>
                            <Icon as={MdOutlinePersonRemove} color={'red.500'} w='24px' h='24px'/>
                        </Button>
                        <Button
                            alignItems='center'
                            justifyContent='center'
                            bg={bgButton}
                            _hover={bgHover}
                            _focus={bgFocus}
                            _active={bgFocus}
                            w='37px'
                            h='37px'
                            lineHeight='100%'
                            borderRadius='10px'
                            isLoading={loading}
                            onClick={() => getResetData()}>
                            <Icon as={MdCached} color={iconColor} w='24px' h='24px'/>
                        </Button>
                        <CompanyPodcastsDeleteAlert deleteFunc={deletePodcastsFunc}
                                                    podcastIds={deletingPodcasts}
                                                    onClose={onClose}
                                                    isOpen={isOpen}
                                                    onOpen={onOpen} key={"deletePodcastsAlert"} />

                    </Stack>
                </Grid>
            </Flex>
            <Flex direction='column' w='100%' overflowX={'auto'}>
                <Table variant='striped' color='gray.500' mt="12px" overflowX={'auto'} minW={'1em'} maxWidth={'none'}>
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <Th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            pe='10px'
                                            borderColor={borderColor}
                                            cursor='pointer'
                                            onClick={header.column.getToggleSortingHandler()}>
                                            <Flex
                                                justifyContent='space-between'
                                                align='center'
                                                fontSize={{sm: '10px', lg: '12px'}}
                                                color='gray.400'>
                                                {flexRender(header.column.columnDef.header, header.getContext())}{{
                                                asc: '',
                                                desc: '',
                                            }[header.column.getIsSorted() as string] ?? null}
                                            </Flex>
                                        </Th>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row) => {
                            return (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <Td
                                                key={cell.id}
                                                fontSize={{sm: '10px', lg: '12px'}}
                                                borderColor='transparent'>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
                {loading && !response?.content?.length &&
                    <Box padding='6' boxShadow='lg'>
                        <SkeletonCircle size='36px'/>
                        <SkeletonText mt='4' noOfLines={4} spacing='4'/>
                    </Box>}
                {!loading && error &&
                    <Center padding='6' boxShadow='lg' justifyContent={"center"} color={"red"}>
                        <Box as='span' fontWeight='bold' fontSize='lg' >
                            {error}
                        </Box>
                    </Center>
                }
            </Flex>
        </Flex>
    );
}
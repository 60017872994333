// Chakra imports
import {Box, Icon, SimpleGrid, useColorModeValue} from '@chakra-ui/react';
// Assets
// Custom components
import TotalAssessed from 'views/admin/default/components/TotalAssessed';
import TotalListenedProgress from 'views/admin/default/components/TotalListenedProgress';
import TotalGenerated from 'views/admin/default/components/TotalGenerated';
import TotalTopicsCreated from './components/TotalTopicsCreated';
import Card from "../../../components/card/Card";
import React from "react";
import TableDataTopListeners from "views/admin/default/components/TableTopListeners";
import tableDataTopListeners from "./variables/tableDataTopListeners";
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import {MdPodcasts, MdRecordVoiceOver, MdWebStories} from 'react-icons/md';

export default function UserReports() {
	// Chakra Color Mode
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 5 }} gap='20px' mb='20px'>
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={<Icon w='32px' h='32px' as={MdRecordVoiceOver} color={brandColor} />}
                        />
                    }
                    name='All Subscribers'
                    value='274'
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={<Icon w='28px' h='28px' as={MdPodcasts} color={brandColor} />}
                        />
                    }
                    name='Released Podcasts'
                    value='9.7k'
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={<Icon w='32px' h='32px' as={MdWebStories} color={brandColor} />}
                        />
                    }
                    name='Created Topics'
                    value='2.3k'
                />
                <MiniStatistics growth='+23%' name='Listening Minutes Progress' value='57.4k' />
                <MiniStatistics name='Inactive/Indifferent Users'  value='56' growth="+5"/>
            </SimpleGrid>
			<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
				<TotalGenerated />
				<TotalTopicsCreated />
			</SimpleGrid>
			<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
				<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
					<TotalAssessed />
					<TotalListenedProgress />
				</SimpleGrid>
                <Card>
                    <TableDataTopListeners tableData={tableDataTopListeners}  />
                </Card>
			</SimpleGrid>
		</Box>
	);
}

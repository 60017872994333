import * as React from "react";
import DatePicker from "react-datepicker";
import {Button, forwardRef, Text, useColorModeValue} from "@chakra-ui/react";
import {DateTime} from "luxon";
import "react-datepicker/dist/react-datepicker.css";
import './SimpleDatePicker.css';

export default function SimpleDatePicker(props: {
    dataFunc: any;
    setFrom: any;
    from: DateTime;
}) {
    const {
        dataFunc,
        setFrom,
        from
    } = props;

    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'});
    const iconColor = useColorModeValue('brand.500', 'white');

    const ExampleCustomInput = forwardRef(({value, onClick}, ref) => (
        <Button
            alignItems='center'
            justifyContent='center'
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            lineHeight='100%'
            borderRadius='10px'
            h='37px'
            onClick={onClick}
            ref={ref}>
            <Text
                color={iconColor}
                fontSize='md'
                fontWeight='500'>
                {value}
            </Text>
        </Button>
    ));
    return (
        <DatePicker
            onChange={(date) => dataFunc(DateTime.fromJSDate(date))}
            selected={from.toJSDate()}
            customInput={<ExampleCustomInput/>}>
            <div style={{color: "red"}}>Don't select deep history, no range validation. Performance is impacted!</div>
        </DatePicker>
    );
}
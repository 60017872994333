import {useState} from "react";
import axios from "axios";
import {AxiosInstance} from "axios/index";
import {Auth} from "aws-amplify";
import {modificationMethods} from "../api/Constants";
import {isConsoleAdminGroup} from "./cognitoGroupsFunction";

const useAxiosFunction = (defaultValue: any): [any, React.Dispatch<any>, string, React.Dispatch<any>, boolean, (configObj: any) => Promise<void>] => {

    const [response, setResponse] = useState<any>(defaultValue);
    const [error, setError] = useState<string>(null);
    const [loading, setLoading] = useState<boolean>(false); //different!
    //const [controller, setController] = useState<AbortController>();

    const axiosRequest = async (configObj: {
        axiosInstance: AxiosInstance;
        method: string;
        url: string;
        body: any;
        requestConfig: any;
    }): Promise<void> => {
        const {
            axiosInstance,
            method,
            url,
            body,
            requestConfig = {}
        } = configObj;

        try {
            setLoading(true);
            if (error)
                setError(null);

            const methodName: string = method.toLowerCase();
            const isAdmin: boolean = await isConsoleAdminGroup();
            const session = await Auth.currentSession();
            axiosInstance.defaults.headers['Authorization'] = session.getIdToken().getJwtToken();

            if (!isAdmin && modificationMethods.includes(methodName))
                throw new Error("Logged-in user is not Console Admin");

            const api = axiosInstance as any;
            const ctrl = new AbortController();
            //setController(ctrl);

            const res = await api[methodName](url, body, {
                ...requestConfig,
                signal: ctrl.signal
            });
            if (res?.data)
                setResponse(res?.data);
            else
                setResponse(defaultValue)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error?.response?.data && error?.response?.data !== 'undefined')
                    setError(error?.response?.data);
                else
                    setError(error.message);
            } else {
                setError((error as Error).message);
            }
            console.log(error)
        } finally {
            setLoading(false);
        }
    }

    /**
     useEffect(() => {
     // useEffect cleanup function
     return () => controller && controller.abort();

     }, [controller]);
     **/

    return [response, setResponse, error, setError, loading, axiosRequest];
}

export default useAxiosFunction
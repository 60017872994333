import {KeyboardEvent} from "react";

export const BACKEND_URL = 'https://f9fc7db3lj.execute-api.eu-north-1.amazonaws.com/dev';
//
// http://localhost:8080
//

export const defaultPageSize: number[] = [25, 50, 100, 200, 500];

export const defaultUserFilter: string = "firstName";

export const amplifyConfig: any =
    {
        "REGION": "eu-north-1",
        "USER_POOL_ID": "eu-north-1_935HmgJ8N",
        "USER_POOL_APP_CLIENT_ID": "5g08bkblue9mfuviqka7mbakkn"
    }

export const ConsoleAdmins: string[] = ["ConsoleAdmin"];

export const modificationMethods: string[] = ["put", "delete", "post", "patch"];

export const TOAST_DURATION_MS: number = 10000;

export const defaultNewUser: {} =
    {
        "firstName": null,
        "lastName": null,
        "nickName": null,
        "email": null,
        "phoneNumber": null,
        "title": null,
        "department": null,
        "address": null,
        "comment": null,
        "googleProfile": null,
        "linkedinProfile": null,
        "iconPhoto": null,
        "status": "ACTIVE",
        "topics": [],
        "podcasts": []
    }

export const defaultNewTopic: {} =
    {
        "topicOrder": 1,
        "title": null,
        "description": null,
        "comment": null,
        "type": "CUSTOM",
        "status": "ACTIVE"
    }

export const defaultNewSourceItem: {} =
    {
        "topicName": null,
        "article": 1,
        "read": false,
        "createDate": null,
        "modifiedDate": null,
        "rowText": null,
        "type": null,
        "version": 0,
        "status": "ACTIVE"
    }

export const STATUS_ACTIVE: string = 'ACTIVE';
export const STATUS_INACTIVE: string = 'INACTIVE';
export const STATUS_DELETED: string = 'DELETED';

export const STATE_RAW: string = 'ROW';
export const STATE_SUMMARIZED: string = 'SUMMARIZED';
export const STATE_READY: string = 'READY';

export function handleEnterKeyPress<T = Element>(f: () => void){
    return handleKeyPress<T>(f, "Enter")
}

export function handleKeyPress<T = Element>(f: () => void, key: string){
    return (e: KeyboardEvent<T>) => {
        if(e.key === key){
            f()
        }
    }
}
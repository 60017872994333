import {Box, Grid, useToast} from "@chakra-ui/react";
import React, {useEffect} from "react";
import useAxiosFunction from "../../../../hooks/useAxiosFunction";
import CompanyUserBanner from "./CompanyUserBanner";
import banner from 'assets/img/auth/banner.png';
import CompanyUserPrivate from "./CompanyUserPrivate";
import CompanyUserSocial from "./CompanyUserSocial";
import CompanyUserTopics from "./CompanyUserTopics";
import {defaultNewUser, TOAST_DURATION_MS} from "../../../../api/Constants";
import {useHistory} from "react-router-dom";
import {newUserSuccessMessage, validateEmail, validateField, ValidationResult} from "../../common/Validation";
import axios from "../../../../api/BackendRest";
import useCognitoGroups from "../../../../hooks/cognitoGroupsFunction";


const CompanyNewUserForm: React.FC = (): JSX.Element => {
    const [response, setResponse, error, setError, loading, axiosFetch] = useAxiosFunction(defaultNewUser);
    const [isAdmin, isConsoleAdmin,] = useCognitoGroups(false);
    const history = useHistory();
    const toast = useToast()

    useEffect(() => {
        isConsoleAdmin();
    }, [])

    useEffect(() => {
        if (response?.id) {
            toast({
                title: newUserSuccessMessage(response),
                duration: TOAST_DURATION_MS,
                status: 'success',
                isClosable: true,
            });
            history.push('/admin/users');
        }
    }, [response?.id])

    const clear = () => {
        setResponse(defaultNewUser)
        setError(null)
    }

    const deleteUser = () => {
        history.push('/admin/users')
    }

    const validate = (name: string, value: string, validation: (name: string, value: string) => ValidationResult): boolean => {
        const result = validation(name, value);
        if (!result.isValid)
            toast({
                title: result.message,
                status: 'error',
                isClosable: true,
            })
        return result.isValid;
    }

    const saveUser = () => {
        if (validate("Email", response.email, validateEmail)
            && validate("NickName", response.nickName, validateField)
            && validate("FirstName", response.firstName, validateField)
            && validate("LastName", response.lastName, validateField))
            axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/users',
                body: response
            });
    }

    return (
        <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
            <Grid templateColumns={{base: '1fr', lg: '1fr'}} gap={{base: '20px', xl: '0px'}}>
                <Grid templateColumns={{base: '1fr', lg: 'repeat(2, 1fr)'}} gap={'20px'}>
                    <CompanyUserBanner id={response.id}
                                       error={error}
                                       dataFunc={clear}
                                       saveFunc={saveUser}
                                       deleteFunc={deleteUser}
                                       newPodcastFunc={clear}
                                       canModify={isAdmin}
                                       loading={loading}
                                       banner={banner}
                                       job={response.title}
                                       status={response.status}
                                       avatar={response.iconPhoto}
                                       lastName={response.lastName}
                                       firstName={response.firstName}
                                       email={response.email}
                                       cognitoUserId={response.cognitoUserId}
                                       podcastCount={0}
                                       topics={response.topics}
                    />
                    <CompanyUserTopics dataFunc={clear}
                                       saveFunc={saveUser}
                                       setResponse={setResponse}
                                       error={error}
                                       loading={loading}
                                       user={response}
                                       allowControl={isAdmin}
                    />

                </Grid>
                <Grid templateColumns={{base: '1fr', lg: 'repeat(2, 1fr)'}} gap={'20px'}>
                    <CompanyUserPrivate dataFunc={clear}
                                        saveFunc={saveUser}
                                        setResponse={setResponse}
                                        error={error}
                                        loading={loading}
                                        user={response}
                                        allowControl={false}
                                        editEmail={true}
                    />
                    <CompanyUserSocial dataFunc={clear}
                                       saveFunc={saveUser}
                                       loading={loading}
                                       error={error}
                                       user={response}
                                       allowControl={false}
                                       setResponse={setResponse}/>
                </Grid>
            </Grid>
        </Box>
    );
}

export default CompanyNewUserForm
import {Button, Icon, Select, Stack, useColorModeValue} from "@chakra-ui/react";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa6";
import {defaultPageSize} from "../../api/Constants";
import * as React from "react";


export default function SimplePager(props: {
    pageSize: number
    loading: boolean;
    response: any;
    dataFunc: any;
    setPageSize: React.Dispatch<any>,
    [x: string]: any;
}) {

    const {
        pageSize,
        loading,
        response,
        dataFunc,
        setPageSize,
        ...rest
    } = props;

    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'});

    return (
        <Stack id={'usersPager'} direction={['column', 'row']} {...rest}>
            <Select
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                borderRadius='10px'
                borderColor={bgButton}
                h='37px'
                w='100px'
                color={iconColor}
                disabled={loading}
                fontSize='md'
                fontWeight='500'
                id={'usersPageSizeSelect'}
                value={pageSize || defaultPageSize[0]}
                onChange={(e) => {
                    setPageSize(parseInt(e.target.selectedOptions[0].value));
                }}>
                {[...defaultPageSize].map((x, i) =>
                    <option itemID={`pageSize${x}`} key={x} id={`pageSize${x}`} value={x}>{x}</option>
                )}
            </Select>
            <Select
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                borderRadius='10px'
                borderColor={bgButton}
                h='37px'
                w='200px'
                color={iconColor}
                disabled={loading}
                fontSize='md'
                fontWeight='500'
                id={'usersPagerSelect'}
                value={response?.number || 0}
                onChange={(e) => dataFunc(parseInt(e.target.selectedOptions[0].value))}>
                {[...Array(response?.totalPages)].map((x, i) =>
                    <option itemID={`page${i}`} key={i} id={`page${i}`}
                            value={i}>Page {i + 1} From {response?.totalPages || 1}</option>
                )}
            </Select>
            <Button
                alignItems='center'
                justifyContent='center'
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                w='37px'
                h='37px'
                lineHeight='100%'
                borderRadius='10px'
                disabled={response?.first || loading}
                onClick={() => dataFunc(response?.number - 1)}>
                <Icon as={FaAngleLeft} color={iconColor} w='22px' h='22px'/>
            </Button>
            <Button
                alignItems='center'
                justifyContent='center'
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                w='37px'
                h='37px'
                lineHeight='100%'
                borderRadius='10px'
                disabled={response?.last || loading}
                onClick={() => dataFunc(response?.number + 1)}>
                <Icon as={FaAngleRight} color={iconColor} w='22px' h='22px'/>
            </Button>
        </Stack>
    );
}
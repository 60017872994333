// Chakra imports
import {Box, Flex, Select, Text, useColorModeValue} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import LineChart from 'components/charts/LineChart';
// Assets
import {lineChartOptionsTotalSpent, lineChartPodcastsTotalGenerated} from 'variables/charts';
import React from "react";

export default function TotalSpent(props: { [x: string]: any }) {
    const {...rest} = props;
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    return (
        <Card justifyContent='center' alignItems='center' flexDirection='column' w='100%' mb='0px'  {...rest}>
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text me='auto' color={textColor} fontSize='medium' fontWeight='500' lineHeight='100%'>
                    Podcasts Released vs Listened
                </Text>
            </Flex>
            <Box minH='350px' minW='100%' mt='auto'>
            <LineChart chartData={lineChartPodcastsTotalGenerated} chartOptions={lineChartOptionsTotalSpent}/>
            </Box>
        </Card>
    );
}

// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Select,
    Stack,
    Tag,
    TagCloseButton,
    TagLabel,
    Text,
    useColorModeValue,
    Wrap,
    WrapItem
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import * as React from "react";
import {useEffect} from "react";
import {MdCached, MdOutlineSaveAs} from "react-icons/md";
import useAxiosFunction from "../../../../hooks/useAxiosFunction";
import axios from "../../../../api/BackendRest";


export default function CompanyUserTopics(props: {
    dataFunc: any;
    saveFunc: any
    loading: boolean;
    error: string;
    user: any;
    allowControl: boolean,
    setResponse: React.Dispatch<any>,
    [x: string]: any;
}) {

    const {
        dataFunc,
        saveFunc,
        loading,
        error,
        user,
        allowControl,
        setResponse,
        ...rest
    } = props;

    const [topics, , topicsError, , topicsLoading, topicsAxiosFetch] = useAxiosFunction([]);

    const getTopics = () => {
        topicsAxiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: '/topics'
        });
    }

    useEffect(() => {
        getTopics();
    }, [])

    const handleTopicTagClick = (e: React.MouseEvent<HTMLButtonElement>, topicId: number) => {
        const userTopics = user.topics.filter((topic: any) => topic.id !== topicId);
        setResponse({...user, "topics": userTopics});
    }

    const handleTopicSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.selectedOptions[0].id) {
            const selectedId = parseInt(e.target.selectedOptions[0].id);
            const existentTopic = user.topics.find((topic: any) => topic.id === selectedId);
            if (!existentTopic) {
                const selectedTopic = topics.find((topic: any) => topic.id === selectedId);
                const updatedTopics = [...user.topics, selectedTopic]
                setResponse({...user, "topics": updatedTopics})
            }
        }
    }

    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.700', 'white');
    const borderColor = useColorModeValue('white !important', '#111C44 !important');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'});

    return (
        <Card mb={{base: '0px', lg: '20px'}} alignItems='center' {...rest}>
            <Flex
                align={{sm: "flex-start", lg: "center"}}
                justify='space-between'
                w='100%'
                px='22px'
                pb='20px'
                mb='10px'
                boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
                <Text color={textColorSecondary} fontSize='xl' fontWeight='600'>
                    Topics
                </Text>
                {allowControl && <Stack direction={['column', 'row']}>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        isLoading={loading}
                        onClick={saveFunc}>
                        {!loading && <Icon as={MdOutlineSaveAs} color={iconColor} w='24px' h='24px'/>}
                    </Button>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        isLoading={loading}
                        onClick={dataFunc}>
                        {!loading && <Icon as={MdCached} color={iconColor} w='24px' h='24px'/>}
                    </Button>
                </Stack>}
            </Flex>
            <Flex width={'100%'} mb="20px">
                <FormControl>
                    <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                               htmlFor="systemTopics" ms="10px">
                        Available Topics
                    </FormLabel>
                    {topics &&
                        <Select placeholder={topicsLoading ? 'Loading ...' : 'Select Topics'}
                                disabled={topicsLoading || loading}
                                onChange={(e) => handleTopicSelection(e)}>
                            {topics.sort((a: any, b: any) => a.topicOrder - b.topicOrder).map((topic: any, i: number) =>
                                <option itemID={topic.id} key={topic.id} id={topic.id}>{topic.title}</option>
                            )}
                        </Select>
                    }
                </FormControl>
            </Flex>

            <Flex flexDirection='column' width={'100%'}>

                <Text color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold" mb="10px"
                      ms="10px">
                    Assigned Topics To User
                </Text>
                <Flex width={'100%'} direction="row" p="12px"
                      wrap="wrap" bg="transparent" border="1px solid" borderColor="secondaryGray.100"
                      borderRadius="10px" minH="160px" h="max-content" cursor="text">
                    {user && user.topics && <Wrap align="flex-start" justifyContent="flex-start" justify="start">
                        {user.topics.sort((a: any, b: any) => a.topicOrder - b.topicOrder).map((topic: any, i: number) =>
                            <WrapItem itemID={topic?.id} key={topic?.id}>
                                <Tag itemID={topic?.id} size="lg" variant='subtle' colorScheme={
                                    topic.type.toUpperCase() === "CUSTOM" ? (
                                        'whatsapp'
                                    ) : 'facebook'
                                }
                                     key={topic?.id}>
                                    <TagLabel>{topic?.title}</TagLabel>
                                    <TagCloseButton isDisabled={topicsLoading || loading}
                                                    onClick={(e) => handleTopicTagClick(e, topic?.id)}/>
                                </Tag>
                            </WrapItem>
                        )
                        }
                    </Wrap>}
                </Flex>
            </Flex>
            {!topicsLoading && topicsError &&
                <Center padding='6' justifyContent={"center"} color={'red.500'} bg={'red.50'} width={'100%'} mb="20px"
                        flexDirection='column'>
                    <Box as='span' fontWeight='bold' fontSize='lg'>
                        {topicsError} {error}
                    </Box>
                    <Box>Input data might rejected by server</Box>
                </Center>
            }
        </Card>
    )
}

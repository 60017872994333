import axios from 'axios';
import {BACKEND_URL} from "./Constants";
import {DateTime} from "luxon";
import {DurationLike} from "luxon/src/duration";
import {SortingState} from "@tanstack/react-table";


export default axios.create({
    baseURL: BACKEND_URL,
    headers: {'Content-Type': 'application/json'}
});

export const dateTimeWithShift = (minusDuration: DurationLike = {weeks: 1}): DateTime => {
    return DateTime.now().minus(minusDuration).startOf('day')
}

export const getSorting = (sorting: SortingState): string => {
    return sorting.map((s) => `sort=${s.id},${s.desc ? 'desc' : 'asc'}`).join('&')
}

export const getMultipleParams = (params: any[], paramsName: string): string => {
    return params.map((param: any) => `${paramsName}=${param}`).join('&')
}
// Chakra imports
import {Avatar, Badge, Box, Button, Center, Flex, Grid, Icon, Stack, Text, useColorModeValue} from '@chakra-ui/react';
import Card from 'components/card/Card';
import {MdCached, MdOutlinePersonRemove, MdOutlineSaveAs, MdPodcasts} from "react-icons/md";
import * as React from "react";
import {STATUS_ACTIVE} from "../../../../api/Constants";

export default function CompanyUserBanner(props: {
    dataFunc: any;
    saveFunc: any;
    deleteFunc: any;
    newPodcastFunc: any;
    loading: boolean;
    canModify: boolean;
    error: string;
    banner: string;
    avatar: string;
    id: number;
    cognitoUserId: string;
    email: string;
    status: string;
    firstName: string;
    lastName: string;
    job: string;
    podcastCount: number;
    topics: [];
    [x: string]: any;
}) {

    const {
        dataFunc,
        saveFunc,
        deleteFunc,
        newPodcastFunc,
        loading,
        canModify,
        error,
        id,
        cognitoUserId,
        email,
        banner,
        avatar,
        status,
        firstName,
        lastName,
        job,
        podcastCount,
        topics,
        ...rest
    } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'gray.400';
    const borderColor = useColorModeValue('white !important', '#111C44 !important');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'});

    return (
        <Card mb={{base: '0px', lg: '20px'}} alignItems='center' {...rest}>
            <Flex
                align={{sm: "flex-start", lg: "center"}}
                justify='space-between'
                w='100%'
                px='22px'
                pb='20px'
                mb='10px'
                boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
                {status && <Badge variant='solid' size={'lg'} colorScheme={
                    status?.toUpperCase() === STATUS_ACTIVE ? (
                        'green'
                    ) : 'red'
                }>
                    {status}
                </Badge>}
                {!status && <Badge variant='outline' size={'lg'} colorScheme={"gray"}>
                    UNKNOWN
                </Badge>}
                <Stack direction={['column', 'row']}>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        isLoading={loading}
                        disabled={!canModify || loading}
                        onClick={deleteFunc}>
                        <Icon as={MdOutlinePersonRemove} color={'red.500'} w='24px' h='24px'/>
                    </Button>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        isLoading={loading}
                        disabled={!canModify || loading}
                        onClick={saveFunc}>
                        <Icon as={MdOutlineSaveAs} color={iconColor} w='24px' h='24px'/>
                    </Button>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        isLoading={loading}
                        onClick={newPodcastFunc}>
                        <Icon as={MdPodcasts} color={iconColor} w='24px' h='24px'/>
                    </Button>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        isLoading={loading}
                        onClick={dataFunc}>
                        <Icon as={MdCached} color={iconColor} w='24px' h='24px'/>
                    </Button>
                </Stack>
            </Flex>
            <Grid width={'100%'} w='100%'
                  alignItems='center' justifyContent='center'
                  templateColumns={{base: 'repeat(3, 1fr)', lg: 'repeat(3, 1fr)'}}
                  justifyItems={'center'}>
                <Flex  alignItems='center' flexDirection='column' width={'100%'}>
                    <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
                        {topics ? topics.length : 0}
                    </Text>

                    <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
                        Topics
                    </Text>
                </Flex>
                <Avatar src={avatar} h='200px' w='200px' mt='-20px' border='5px solid' borderColor={borderColor}/>
                <Flex  alignItems='center' flexDirection='column' width={'100%'}>
                    <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
                        {podcastCount}
                    </Text>

                    <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
                        Podcasts
                    </Text>
                </Flex>
            </Grid>
            {firstName && lastName && <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
                {firstName && firstName.length <= 25 ? firstName : `${firstName.slice(0, 25)}...`} {lastName && lastName.length <= 25 ? lastName : `${lastName.slice(0, 25)}...`}
            </Text>}
            {!firstName && !lastName && <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
                --- ---
            </Text>}
            {email && <Text color={textColorSecondary} fontWeight='bold' fontSize='xl'>
                {email}
            </Text>}
            {!email && <Text color={textColorSecondary} fontWeight='bold' fontSize='xl'>
                ---
            </Text>}
            {job && <Text color={textColorSecondary} fontSize='sm'>
                {job && job.length <= 256 ? job : `${job.slice(0, 256)}...`}
            </Text>}
            {!job && <Text color={textColorSecondary} fontSize='sm'>
                Title or Job Tile still absent for user
            </Text>}
            {!loading && error &&
                <Center padding='6' justifyContent={"center"} color={'red.500'} bg={'red.50'} width={'100%'}
                        flexDirection='column'>
                    <Box as='span' fontWeight='bold' fontSize='lg'>
                        {error}
                    </Box>
                    <Box>Sent data has been rejected by server</Box>
                </Center>
            }
        </Card>
    );
}

// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormLabel,
    Icon, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper,
    Stack,
    Text,
    Textarea,
    useColorModeValue
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import * as React from "react";
import {MdCached, MdOutlinePersonRemove, MdOutlineSaveAs} from "react-icons/md";
import {FaBattleNet, FaJenkins, FaReact} from 'react-icons/fa';


export default function CompanyTopicDetails(props: {
    dataFunc: any;
    saveFunc: any;
    deleteFunc: any;
    canModify: boolean;
    loading: boolean;
    error: string;
    topic: any;
    allowControl: boolean;
    setResponse: React.Dispatch<any>,
    [x: string]: any;
}) {

    const {
        dataFunc,
        saveFunc,
        deleteFunc,
        canModify,
        loading,
        error,
        topic,
        allowControl,
        setResponse,
        ...rest
    } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.700', 'white');
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'});

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const {id, value} = event.target;
        setResponse({
            ...topic,
            [id]: value,
        })
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {id, value} = event.target;
        setResponse({
            ...topic,
            [id]: value?.trim()
        })
    }
    return (
        <Card mb={{base: '0px', lg: '20px'}} alignItems='center' {...rest}>
            <Flex
                align={{sm: "flex-start", lg: "center"}}
                justify='space-between'
                w='100%'
                px='22px'
                pb='20px'
                mb='10px'
                boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
                <Text color={textColorSecondary} fontSize='xl' fontWeight='600'>
                    Custom Topic's Details
                </Text>
                {allowControl && <Stack  direction={['column', 'row']}>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        isLoading={loading}
                        disabled={!canModify}
                        onClick={deleteFunc}>
                        {!loading && <Icon as={MdOutlinePersonRemove} color={'red.500'} w='24px' h='24px'/>}
                    </Button>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        isLoading={loading}
                        disabled={!canModify}
                        onClick={saveFunc}>
                        {!loading && <Icon as={MdOutlineSaveAs} color={iconColor} w='24px' h='24px'/>}
                    </Button>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        isLoading={loading}
                        onClick={dataFunc}>
                        {!loading && <Icon as={MdCached} color={iconColor} w='24px' h='24px'/>}
                    </Button>
                </Stack>}
            </Flex>
            <Flex flexDirection='column' width={'100%'} mb="20px">
                <Flex width={'100%'} mb="20px">
                    <Flex h={'24px'} w={'24px'}/>
                    <FormControl>
                        <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                                   htmlFor="topicOrder" ms="10px">
                            Topic Order
                        </FormLabel>
                        <NumberInput id={"topicOrder"} value={topic.topicOrder || 1} isDisabled={loading} color={textColorPrimary}
                                     defaultValue={1} max={100} clampValueOnBlur={true}
                                     width={{base: '100%', sm: '100%', md: '50%', lg: '50%', '2xl': '35%'}}
                                     onChange={(value, valueAsNumber) => setResponse({...topic, 'topicOrder': valueAsNumber})}>
                            <NumberInputField color={textColorPrimary} />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>
                </Flex>
                <Flex width={'100%'} mb="20px">
                    <Icon as={FaReact} h={'24px'} w={'24px'}/>
                    <FormControl isRequired isInvalid={!loading && !topic.title?.trim()}>
                        <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                                   htmlFor="title" ms="10px">
                            Topic Name
                        </FormLabel>
                        <Textarea id="title"
                                  placeholder="Put user's custom topic name or title"
                                  value={topic.title || ""} color={textColorPrimary}
                                  onBlur={handleBlur}
                                  onChange={handleChange}/>
                    </FormControl>
                </Flex>
                <Flex width={'100%'} mb="20px">
                    <Icon as={FaBattleNet} h={'24px'} w={'24px'}/>
                    <FormControl>

                        <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                                   htmlFor="description" ms="10px">
                            Topic Description
                        </FormLabel>
                        <Textarea id="description" placeholder="Put user's custom topic detailed description"
                                  value={topic.description || ""} color={textColorPrimary}
                                  onBlur={handleBlur}
                                  onChange={handleChange}/>
                    </FormControl>
                </Flex>
                <Flex width={'100%'} mb="20px">
                    <Icon as={FaJenkins} h={'24px'} w={'24px'}/>
                    <FormControl>
                        <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                                   htmlFor="comment" ms="10px">
                            Topic Comment
                        </FormLabel>
                        <Textarea id="comment" placeholder="Put user's Linked-In profile URL"
                                  value={topic.comment || ""} color={textColorPrimary}
                                  onBlur={handleBlur}
                                  onChange={handleChange}/>
                    </FormControl>
                </Flex>
                {!loading && error &&
                    <Center padding='6' justifyContent={"center"} color={'red.500'} bg={'red.50'} width={'100%'}
                            flexDirection='column'>
                        <Box as='span' fontWeight='bold' fontSize='lg'>
                            {error}
                        </Box>
                        <Box>Sent data has been rejected by server</Box>
                    </Center>
                }
            </Flex>
        </Card>
    )
}

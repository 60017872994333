// Chakra imports
import {
    Box,
    Button,
    Center,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
    Stack,
    Text,
    Textarea,
    useColorModeValue
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import * as React from "react";
import {useEffect} from "react";
import {MdCached, MdOutlinePersonRemove, MdOutlineSaveAs} from "react-icons/md";
import useAxiosFunction from "../../../../hooks/useAxiosFunction";
import axios from "../../../../api/BackendRest";
import {STATE_RAW, STATE_READY, STATE_SUMMARIZED} from "../../../../api/Constants";


export default function NewsSourceDetails(props: {
    dataFunc: any;
    saveFunc: any;
    deleteFunc: any;
    canModify: boolean;
    loading: boolean;
    error: string;
    topic: any;
    allowControl: boolean;
    setResponse: React.Dispatch<any>,
    [x: string]: any;
}) {

    const {
        dataFunc,
        saveFunc,
        deleteFunc,
        canModify,
        loading,
        error,
        topic,
        allowControl,
        setResponse,
        ...rest
    } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.700', 'white');
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'});

    const [topics, , , , topicsLoading, topicsAxiosFetch] = useAxiosFunction([]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
        const {id, value} = event.target;
        setResponse({
            ...topic,
            [id]: value,
        })
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const {id, value} = event.target;
        setResponse({
            ...topic,
            [id]: value?.trim()
        })
    }

    const getTopics = () => {
        topicsAxiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: '/topics'
        });
    }

    useEffect(() => {
        getTopics();
    }, [])

    return (
        <Card mb={{base: '0px', lg: '20px'}} alignItems='center' {...rest}>
            <Flex
                align={{sm: "flex-start", lg: "center"}}
                justify='space-between'
                w='100%'
                px='22px'
                pb='20px'
                mb='10px'
                boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
                <Text color={textColorSecondary} fontSize='xl' fontWeight='600'>
                    News Source Item
                </Text>
                {allowControl && <Stack direction={['column', 'row']}>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        isLoading={loading}
                        disabled={!canModify}
                        onClick={deleteFunc}>
                        {!loading && <Icon as={MdOutlinePersonRemove} color={'red.500'} w='24px' h='24px'/>}
                    </Button>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        isLoading={loading}
                        disabled={!canModify}
                        onClick={saveFunc}>
                        {!loading && <Icon as={MdOutlineSaveAs} color={iconColor} w='24px' h='24px'/>}
                    </Button>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        isLoading={loading}
                        onClick={dataFunc}>
                        {!loading && <Icon as={MdCached} color={iconColor} w='24px' h='24px'/>}
                    </Button>
                </Stack>}
            </Flex>
            <Flex flexDirection='column' width={'100%'} mb="20px">
                <Flex width={'100%'} mb="20px">
                    <Flex h={'24px'} w={'24px'}/>
                    <FormControl>
                        <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                                   htmlFor="read" ms="10px">
                            Processed
                        </FormLabel>
                        <Checkbox id={"read"} colorScheme='green' isChecked={topic.read || false}
                                  color={textColorSecondary} disabled={loading}
                                  width={{base: '100%', sm: '100%', md: '50%', lg: '50%', '2xl': '35%'}}
                                  onChange={(event) => setResponse({...topic, 'read': event.target.checked})}>
                            Checked is to skip processing or already processed
                        </Checkbox>
                    </FormControl>
                </Flex>
                <Flex width={'100%'} mb="20px">
                    <Flex h={'24px'} w={'24px'}/>
                    <FormControl>
                        <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                                   htmlFor="article" ms="10px">
                            Article
                        </FormLabel>
                        <NumberInput id={"article"} value={topic.article || 0} isDisabled={loading} color={textColorPrimary}
                                     defaultValue={1} max={100} clampValueOnBlur={true}
                                     width={{base: '100%', sm: '100%', md: '50%', lg: '50%', '2xl': '35%'}}
                                     onChange={(value, valueAsNumber) => setResponse({...topic, 'article': valueAsNumber})}>
                            <NumberInputField color={textColorPrimary} />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>
                </Flex>
                <Flex width={'100%'} mb="20px">
                    <Flex h={'24px'} w={'24px'}/>
                    <FormControl>
                        <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                                   htmlFor="type" ms="10px">
                            Available Type
                        </FormLabel>
                        <Select onChange={handleChange} id={"type"} value={topic.type || ""} color={textColorPrimary}
                                placeholder={loading ? 'Loading ...' : 'Select Type'}
                                width={{base: '100%', sm: '100%', md: '50%', lg: '50%', '2xl': '35%'}}
                                disabled={loading}>
                            <option value={STATE_RAW}>Raw</option>
                            <option value={STATE_READY}>Ready</option>
                            <option value={STATE_SUMMARIZED}>Summarized</option>
                        </Select>
                    </FormControl>
                </Flex>
                <Flex width={'100%'} mb="20px">
                    <Flex h={'24px'} w={'24px'}/>
                    <FormControl>
                        <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                                   htmlFor="topicName" ms="10px">
                            Topic Name
                        </FormLabel>
                        {topics &&
                            <Select id={"topicName"} placeholder={topicsLoading ? 'Loading ...' : 'Select Topics'}
                                    disabled={topicsLoading || loading} color={textColorPrimary}
                                    width={{base: '100%', sm: '100%', md: '50%', lg: '50%', '2xl': '35%'}}
                                    onChange={handleChange} value={topic.topicName || ""}>
                                {topics.sort((a: any, b: any) => a.topicOrder - b.topicOrder).map((topic: any, i: number) =>
                                    <option itemID={topic.id} key={topic.id} id={topic.id}>{topic.title}</option>
                                )}
                            </Select>
                        }
                    </FormControl>
                </Flex>
                <Flex width={'100%'} mb="20px">
                    <Flex h={'24px'} w={'24px'}/>
                    <FormControl>
                        <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                                   htmlFor="rowText" ms="10px">
                            Source Raw Text
                        </FormLabel>
                        <Textarea id="rowText" placeholder="Put raw text for the news, RAW or SUMMARY"
                                  value={topic.rowText || ""} color={textColorPrimary}
                                  minHeight={512}
                                  disabled={loading}
                                  onBlur={handleBlur}
                                  onChange={handleChange}/>
                    </FormControl>
                </Flex>
                {!loading && error &&
                    <Center padding='6' justifyContent={"center"} color={'red.500'} bg={'red.50'} width={'100%'}
                            flexDirection='column'>
                        <Box as='span' fontWeight='bold' fontSize='lg'>
                            {error}
                        </Box>
                        <Box>Sent data has been rejected by server</Box>
                    </Center>
                }
            </Flex>
        </Card>
    )
}

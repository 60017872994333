// Chakra imports
import {Flex, Text, useColorModeValue} from '@chakra-ui/react';
import Card from 'components/card/Card';
// Custom components
import SwitchField from 'components/fields/SwitchField';

export default function CompanyNotifications(props: { [x: string]: any }) {
	const { ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	return (
		<Card mb='20px' {...rest}>
			<Flex align='center' w='100%' justify='space-between' mb='30px'>
				<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mb='4px'>
					Notifications
				</Text>
			</Flex>
			<SwitchField
				isChecked={true}
				reversed={true}
				fontSize='sm'
				mb='20px'
				id='1'
				label='User unboard notifications'
			/>
			<SwitchField reversed={true} fontSize='sm' mb='20px' id='2' label='User modify notifications' />
			<SwitchField reversed={true} fontSize='sm' mb='20px' id='3' label='Topic modify notifications' />
			<SwitchField reversed={true} fontSize='sm' mb='20px' id='4' label='Podcast released notifications' />
			<SwitchField reversed={true} fontSize='sm' mb='20px' id='5' label='Podcast errors notifications' />
			<SwitchField reversed={true} fontSize='sm' mb='20px' id='7' label='Users Inactivity notifications' />
			<SwitchField reversed={true} fontSize='sm' mb='20px' id='8' label='Monthly product changes' />
			<SwitchField reversed={true} fontSize='sm' mb='20px' id='9' label='Subscribe to newsletter' />
		</Card>
	);
}

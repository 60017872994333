import {Icon} from '@chakra-ui/react';
import {MdArticle, MdCoPresent, MdHome, MdPerson, MdPodcasts, MdWebStories} from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import Profile from 'views/admin/profile';
import CompanyUsers from "views/admin/users";
import CompanyTopics from "./views/admin/topics";
// Auth Imports
import NewsSources from "./views/admin/news";
import CompanyPodcasts from "./views/admin/podcasts";

// Auth Imports

const routes = [
    {
        name: 'Main Dashboard',
        layout: '/admin',
        path: '/default',
        visible: true,
        strict: false,
        icon: <Icon as={MdHome} width='20px' height='20px' color='inherit'/>,
        component: MainDashboard
    },
    {
        name: 'Company Profile',
        layout: '/admin',
        path: '/profile',
        visible: true,
        strict: false,
        icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit'/>,
        component: Profile
    },
    {
        name: 'Company Users',
        layout: '/admin',
        path: '/users',
        visible: true,
        strict: false,
        icon: <Icon as={MdCoPresent} width='20px' height='20px' color='inherit'/>,
        component: CompanyUsers
    },
    {
        name: 'Company Topics',
        layout: '/admin',
        path: '/topics',
        visible: true,
        strict: false,
        icon: <Icon as={MdWebStories} width='20px' height='20px' color='inherit'/>,
        component: CompanyTopics
    },
    {
        name: 'News Source',
        layout: '/admin',
        path: '/news',
        visible: true,
        strict: false,
        icon: <Icon as={MdArticle} width='20px' height='20px' color='inherit'/>,
        component: NewsSources
    },
    {
        name: 'Podcasts',
        layout: '/admin',
        path: '/podcasts',
        visible: true,
        strict: false,
        icon: <Icon as={MdPodcasts} width='20px' height='20px' color='inherit'/>,
        component: CompanyPodcasts
    },

];

export default routes;

// Chakra imports
import {AtSignIcon, EmailIcon, PhoneIcon, SettingsIcon} from '@chakra-ui/icons';
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    SimpleGrid,
    Stack,
    Text,
    Textarea,
    useColorModeValue
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import * as React from "react";
import {MdCached, MdOutlineSaveAs} from "react-icons/md";
import {PersonIcon} from "../../../../components/icons/Icons";


export default function CompanyUserPrivate(props: {
    dataFunc: any;
    saveFunc: any;
    loading: boolean;
    error: string;
    user: any;
    allowControl: boolean;
    editEmail: boolean;
    setResponse: React.Dispatch<any>,
    [x: string]: any;
}) {

    const {
        dataFunc,
        saveFunc,
        loading,
        error,
        user,
        allowControl,
        editEmail,
        setResponse,
        ...rest
    } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.700', 'white');
    const borderColor = useColorModeValue('white !important', '#111C44 !important');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'});

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const {id, value} = event.target;
        setResponse({
            ...user,
            [id]: value,
        })
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {id, value} = event.target;
        setResponse({
            ...user,
            [id]: value?.trim()
        })
    }

    return (
        <Card mb={{base: '0px', lg: '20px'}} alignItems='center' {...rest}>
            <Flex
                align={{sm: "flex-start", lg: "center"}}
                justify='space-between'
                w='100%'
                px='22px'
                pb='20px'
                mb='10px'
                boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
                <Text color={textColorSecondary} fontSize='xl' fontWeight='600'>
                    Private
                </Text>
                {allowControl && <Stack direction={['column', 'row']}>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        isLoading={loading}
                        onClick={saveFunc}>
                        {!loading && <Icon as={MdOutlineSaveAs} color={iconColor} w='24px' h='24px'/>}
                    </Button>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        isLoading={loading}
                        onClick={dataFunc}>
                        {!loading && <Icon as={MdCached} color={iconColor} w='24px' h='24px'/>}
                    </Button>
                </Stack>}
            </Flex>
            <SimpleGrid columns={{md: 2, sm: 1}} width={'100%'}
                        spacing={{base: "20px", xl: "20px"}}
                        templateColumns={{md: "repeat(2, minmax(0, 1fr))", sm: "repeat(1, minmax(0, 1fr))"}}>

                <FormControl isRequired id={'nickNameForm'} isInvalid={!loading && !user.nickName?.trim()}>
                    <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                               htmlFor="nickName" ms="10px">
                        Nickname
                    </FormLabel>
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<AtSignIcon color='gray.300'/>}
                        />
                        <Input id="nickName" placeholder="john.william" value={user.nickName || ""}
                               color={textColorPrimary}
                               onBlur={handleBlur}
                               onChange={handleChange}/>
                    </InputGroup>
                </FormControl>
                <FormControl isRequired id={'emailForm'} isInvalid={!loading && !user.email?.trim()}>
                    <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold" htmlFor="email"
                               ms="10px">
                        Email
                    </FormLabel>
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<EmailIcon color='gray.300'/>}
                        />
                        <Input id="email" placeholder="john.william@enlightme.ai" value={user.email || ""}
                               color={textColorPrimary}
                               readOnly={!editEmail}
                               onBlur={handleBlur}
                               onChange={handleChange}/>
                    </InputGroup>
                </FormControl>
                <FormControl isRequired id={'firstNameForm'} isInvalid={!loading && !user.firstName?.trim()}>
                    <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                               htmlFor="firstName" ms="10px">
                        First Name
                    </FormLabel>
                    <Input id="firstName" placeholder="John" value={user.firstName || ""} color={textColorPrimary}
                           maxLength={25}
                           onBlur={handleBlur}
                           onChange={handleChange}/>
                </FormControl>
                <FormControl isRequired id={'lastNameForm'} isInvalid={!loading && !user.lastName?.trim()}>
                    <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                               htmlFor="lastName" ms="10px">
                        Last Name
                    </FormLabel>
                    <Input id="lastName" placeholder="William" value={user.lastName || ""} color={textColorPrimary}
                           maxLength={25}
                           onBlur={handleBlur}
                           onChange={handleChange}/>
                </FormControl>
                <FormControl mb="20px">
                    <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                               htmlFor="phoneNumber" ms="10px">
                        Phone Number
                    </FormLabel>
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<PhoneIcon color='gray.300'/>}
                        />
                        <Input id="phoneNumber" placeholder="+972541234567" value={user.phoneNumber || ""} type={"tel"}
                               color={textColorPrimary}
                               onBlur={handleBlur}
                               onChange={handleChange}/>
                    </InputGroup>
                </FormControl>
                <FormControl mb="20px">
                    <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                               htmlFor="department" ms="10px">
                        Department
                    </FormLabel>
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<SettingsIcon color='gray.300'/>}
                        />
                        <Input id="department" placeholder="R&D" value={user.department || ""} color={textColorPrimary}
                               maxLength={64}
                               onBlur={handleBlur}
                               onChange={handleChange}/>
                    </InputGroup>
                </FormControl>
            </SimpleGrid>
            <Flex width={'100%'} mb="20px">
                <FormControl>
                    <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                               htmlFor="title" ms="10px">
                        Job Title
                    </FormLabel>
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<PersonIcon color='gray.300'/>}
                        />
                        <Input id="title" placeholder="Softeare Engineer" value={user.title || ""} type={"text"}
                               maxLength={256}
                               color={textColorPrimary}
                               onBlur={handleBlur}
                               onChange={handleChange}/>
                    </InputGroup>
                </FormControl>
            </Flex>

            <Flex width={'100%'} mb="20px">
                <FormControl>
                    <FormLabel color={textColorSecondary} display="flex" fontSize="sm" fontWeight="bold"
                               htmlFor="comment" ms="10px">
                        User Related Comment
                    </FormLabel>
                    <Textarea id="comment" placeholder="Put some important comment on this user in 512 characters"
                              maxLength={512}
                              value={user.comment || ""} color={textColorPrimary}
                              onBlur={handleBlur}
                              onChange={handleChange}/>
                </FormControl>
            </Flex>
        </Card>
    )
}

import {Input, Select, Stack, useColorModeValue} from "@chakra-ui/react";
import * as React from "react"
import {handleEnterKeyPress} from "../../api/Constants";


export default function SimpleFilter(props: {
    filterRef: React.MutableRefObject<HTMLSelectElement>;
    filterValueRef: React.MutableRefObject<HTMLInputElement>;
    loading: boolean;
    dataFunc: any;
    children: React.ReactNode;
    [x: string]: any;
}) {

    const {
        filterRef,
        filterValueRef,
        loading,
        dataFunc,
        children,
        ...rest
    } = props;

    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'});


    return (
        <Stack id={'fieldFilter'} direction={['column', 'row']} {...rest} >
            <Input alignItems='center'
                   justifyContent='center'
                   bg={bgButton}
                   _hover={bgHover}
                   _focus={bgFocus}
                   _active={bgFocus}
                   borderColor={bgButton}
                   w='250px'
                   h='37px'
                   fontSize='md'
                   fontWeight='500'
                   color={iconColor}
                   disabled={loading}
                   placeholder='Input search, press Enter'
                   type={'text'}
                   borderRadius='10px'
                   ref={filterValueRef}
                   onKeyDown={handleEnterKeyPress(dataFunc)}
            >
            </Input>
            <Select
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                borderRadius='10px'
                borderColor={bgButton}
                h='37px'
                w='150px'
                color={iconColor}
                disabled={loading}
                fontSize='md'
                fontWeight='500'
                id={'usersPageSizeSelect'}
                ref={filterRef}
            >
                {children}
            </Select>
        </Stack>
    );
}
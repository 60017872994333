import validator from "validator";
import {STATUS_DELETED} from "../../../api/Constants";

export type ValidationResult = {
    isValid: boolean;
    message: string;
}

export const isDeleted = (obj: any): boolean => {
    return obj?.status?.toUpperCase() === STATUS_DELETED
}

export const validateEmail = (name: string, value: string): ValidationResult => {
    if (!value?.trim()) {
        return {isValid: false, message: `"${name}" is required field and important`};
    } else if (!validator.isEmail(value)) {
        return {isValid: false, message: `"${name}" has invalid format and not acceptable to be processed`};
    } else
        return {isValid: true, message: null};
}

export const validateField = (name: string, value: string): ValidationResult => {
    if (!value?.trim()) {
        return {isValid: false, message: `"${name}" is required field and important`};
    } else
        return {isValid: true, message: null};
}

export const newUserSuccessMessage = (user: any) => {
    return `Successfully CREATED user with Id: "${user?.id}", and Email: "${user?.email}"`
}

export const deleteUserSuccessMessage = (user: any) => {
    return `Successfully DELETED user with Id: "${user?.id}", and Email: "${user?.email}"`;
}

export const modifiedUserSuccessMessage = (user: any, oldVer: number, newVer: number) => {
    return `Successfully UPDATED user with Id: "${user?.id}", and Email: "${user?.email}", version changes from ${oldVer} to ${newVer}`;
}

export const newPodcastUserSuccessMessage = (user: any, value: any) => {
    return `Successfully sent request to generate new podcast for user with Id: "${user?.id}", and Email: "${user?.email}", status code is ${value?.status}`;
}

export const newPodcastUserErrorMessage = (user: any, value: any) => {
    return `Error occurred while sending request to generate new podcast for user with Id: "${user?.id}", and Email: "${user?.email}", status code is ${value?.status}, content: "${value?.data}", status text: "${value?.statusText}"`;
}

export const newTopicSuccessMessage = (topic: any) => {
    return `Successfully CREATED record with Id: "${topic?.id}"`
}

export const deleteTopicSuccessMessage = (topic: any) => {
    return `Successfully DELETED record with Id: "${topic?.id}", and Version: "${topic?.version}"`;
}

export const modifiedTopicSuccessMessage = (topic: any, oldVer: number, newVer: number) => {
    return `Successfully UPDATED record with Id: "${topic?.id}", version changes from ${oldVer} to ${newVer}`;
}
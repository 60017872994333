// Chakra imports
// Custom components
import Card from 'components/card/Card';
import PieChart from 'components/charts/PieChart';
import {assessedPieChartData, assessPieChartOptions} from 'variables/charts';
import {Box, Flex, Text, useColorModeValue} from "@chakra-ui/react";
import React from "react";

export default function Conversion(props: { [x: string]: any }) {
    const {...rest} = props;

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const cardColor = useColorModeValue('white', 'navy.700');
    const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
    return (
        <Card p='20px' alignItems='center' flexDirection='column' w='100%'  {...rest}>
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text me='auto' color={textColor} fontSize='medium' fontWeight='500' lineHeight='100%'>
                    Users Podcasts Assessment
                </Text>
            </Flex>
            <Box minH='300px' minW='100%' mt='auto'>
                <PieChart h='100%' w='100%' chartData={assessedPieChartData} chartOptions={assessPieChartOptions}/>
            </Box>
        </Card>
    );
}

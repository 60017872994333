import {Box, useToast} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import useAxiosFunction from "../../../../hooks/useAxiosFunction";
import axios from "../../../../api/BackendRest";

import {TOAST_DURATION_MS} from "../../../../api/Constants";
import {deleteTopicSuccessMessage, isDeleted, modifiedTopicSuccessMessage} from "../../common/Validation";
import NewsSourceDetails from "./NewsSourceDetails";
import NewsSourceGeneral from "./NewsSourceGeneral";
import useCognitoGroups from "../../../../hooks/cognitoGroupsFunction";

interface PageRouteParams {
    newsID: string
}

const NewsSourceForm: React.FC = (): JSX.Element => {
    const params: PageRouteParams = useParams<PageRouteParams>();
    const toast = useToast()
    const [response,setResponse,error, ,loading, axiosFetch] = useAxiosFunction({});

    const [version, setVersion] = useState<number>()
    const [isAdmin, isConsoleAdmin,] = useCognitoGroups(false);
    const history = useHistory();

    useEffect(() => {
        if (isDeleted(response)) {
            toast({
                title: deleteTopicSuccessMessage(response),
                duration: TOAST_DURATION_MS,
                status: 'info',
                isClosable: true,
            });
            history.push('/admin/news');
        }
    }, [response?.status])

    useEffect(() => {
        isConsoleAdmin();
        getData();
    }, [])

    useEffect(() => {
        if ((version !== undefined)
            && response?.version && (version < response.version)
            && !isDeleted(response))
            toast({
                title: modifiedTopicSuccessMessage(response, version, response.version),
                duration: TOAST_DURATION_MS,
                status: 'success',
                isClosable: true,
            });
        setVersion(prev => {
            return response.version
        })
    }, [response?.version]);

    const getData = () => {
        axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: `/news/source/${params.newsID}`
        });
    }

    const saveData = () => {
        axiosFetch({
            axiosInstance: axios,
            method: 'put',
            url: '/news/source',
            body: response
        });
    }

    const deleteData = () => {
        axiosFetch({
            axiosInstance: axios,
            method: 'delete',
            url: `/news/source/${params.newsID}`
        });
    }

    return (
        <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
            {/* Main Fields */}
            <NewsSourceGeneral topic={response}/>
            <NewsSourceDetails dataFunc={getData}
                               saveFunc={saveData}
                               deleteFunc={deleteData}
                               canModify={isAdmin}
                               loading={loading}
                               error={error}
                               topic={response}
                               allowControl={true}
                               setResponse={setResponse}/>
        </Box>
    );
}

export default NewsSourceForm
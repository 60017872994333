// Chakra imports
import {Box, Grid} from '@chakra-ui/react';

// Custom components

// Assets
import banner from 'assets/img/auth/banner.png';
import avatar from 'assets/img/avatars/avatar4.png';
import CompanyBanner from "./components/CompanyBanner";
import CompanyStorage from './components/CompanyStorage';
import CompanyGeneral from './components/CompanyGeneral';
import CompanyNotifications from "./components/CompanyNotifications";
import CompanyPodcasts from './components/CompanyPodcasts';
import CompanySupport from "./components/CompanySupport";

export default function Overview() {
    return (
        <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
            {/* Main Fields */}
            <Grid
                templateColumns={{
                    base: '1fr',
                    lg: '1.62fr 1.34fr 1.34fr'
                }}
                templateRows={{
                    base: 'repeat(3, 1fr)',
                    lg: '1fr'
                }}
                gap={{base: '20px', xl: '20px'}}>
                <CompanyBanner
                    gridArea='1 / 1 / 2 / 2'
                    banner={banner}
                    avatar="https://enlightme.ai/wp-content/uploads/2023/08/YakovBecker.png"
                    name='YAKOV BECKER'
                    job='CEO & Co-founder'
                    topics='2.3k'
                    podcasts='9.7k'
                    users='274'
                />
                <CompanyStorage gridArea={{base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3'}} used={358.6} total={500}/>
                <CompanyPodcasts gridArea={{base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3'}} used={9785} total={50000}/>
            </Grid>
            <Grid
                mb='20px'
                templateColumns={{
                    base: '1fr',
                    lg: 'repeat(2, 1fr)',
                    '2xl': '1.34fr 1.62fr 1fr'
                }}
                templateRows={{
                    base: '1fr',
                    lg: 'repeat(2, 1fr)',
                    '2xl': '1fr'
                }}
                gap={{base: '20px', xl: '20px'}}>
                <CompanySupport
                    banner={banner}
                    avatar={avatar}
                    name='Adela Parkson'
                    job='Product Designer'
                    posts='17'
                    followers='9.7k'
                    following='274'
                />
                <CompanyGeneral gridArea={{base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3'}} minH='365px' pe='20px'/>
                <CompanyNotifications
                    used={25.6}
                    total={50}
                    gridArea={{
                        base: '3 / 1 / 4 / 2',
                        lg: '2 / 1 / 3 / 3',
                        '2xl': '1 / 3 / 2 / 4'
                    }}
                />
            </Grid>
        </Box>
    );
}

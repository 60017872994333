import {Tag, Wrap, WrapItem} from "@chakra-ui/react";
import * as React from "react";

export default function CompanyUsersTopics(props: { [x: string]: any }) {
    const {topics} = props
    return (
        <Wrap align="flex-start" justifyContent="flex-start" justify="start">
            {topics.sort((a: any,b: any) => a.topicOrder - b.topicOrder).map((topic: any, i: number) =>
                <WrapItem itemID={topic?.id} key={topic?.id}>
                    <Tag itemID={topic?.id} size="sm" variant='subtle' colorScheme={
                        topic.type.toUpperCase() === "CUSTOM" ? (
                            'whatsapp'
                        ) : 'facebook'
                    } key={topic?.id}>{topic?.title}</Tag>
                </WrapItem>
            )
            }
        </Wrap>
    );
}
// Chakra imports
import {Box, Portal, useDisclosure} from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import {SidebarContext} from 'contexts/SidebarContext';
import React, {useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import routes from 'routes';
import CompanyUser from "views/admin/user";
import CompanyNewUserForm from "views/admin/user/components/CompanyNewUserForm";
import NewsSource from "views/admin/newsitem";
import CompanyNewTopicForm from "views/admin/topic/components/CompanyNewTopicForm";
import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {Amplify} from 'aws-amplify';
import {amplifyConfig} from "../../api/Constants";
import NewsSourceNewForm from "views/admin/newsitem/components/NewsSourceNewForm";
import CompanyTopic from 'views/admin/topic';
// Auth Imports
// Configure Amplify in index file or root file
Amplify.configure({
    Auth: {
        region: amplifyConfig.REGION,
        userPoolId: amplifyConfig.USER_POOL_ID,
        userPoolWebClientId: amplifyConfig.USER_POOL_APP_CLIENT_ID
    }
})


// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
    const {...rest} = props;
    // states and functions
    const [fixed] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    // functions for changing the states from components
    const getRoute = () => {
        return window.location.pathname !== '/admin/full-screen-maps';
    };
    const getActiveRoute = (routes: RoutesType[]): string => {
        let activeRoute = 'Details';
        for (let i = 0; i < routes.length; i++) {
            if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i].name;
            }
        }
        return activeRoute;
    };
    const getActiveNavbar = (routes: RoutesType[]): boolean => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i].secondary;
            }
        }
        return activeNavbar;
    };
    const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i].name;
            }
        }
        return activeNavbar;
    };
    const getRoutes = (routes: RoutesType[]): any => {
        return routes.map((route: RoutesType, key: any) => {
            if (route.layout === '/admin') {
                return <Route path={route.layout + route.path} component={route.component} key={key}/>;
            } else {
                return null;
            }
        });
    };
    document.documentElement.dir = 'ltr';
    const {onOpen} = useDisclosure();
    return (
        <Authenticator hideSignUp={true} variation={'modal'}>
            {({signOut, user}) => (
                <Box>
                    <SidebarContext.Provider
                        value={{
                            toggleSidebar,
                            setToggleSidebar
                        }}>
                        <Sidebar routes={routes} display='none' {...rest} />
                        <Box
                            float='right'
                            minHeight='100vh'
                            height='100%'
                            overflow='auto'
                            position='relative'
                            maxHeight='100%'
                            w={{base: '100%', xl: 'calc( 100% - 290px )'}}
                            maxWidth={{base: '100%', xl: 'calc( 100% - 290px )'}}
                            transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
                            transitionDuration='.2s, .2s, .35s'
                            transitionProperty='top, bottom, width'
                            transitionTimingFunction='linear, linear, ease'>
                            <Portal>
                                <Box>
                                    <Navbar
                                        onOpen={onOpen}
                                        logOut={signOut}
                                        logoText={'EnlightMe Admin PRO'}
                                        brandText={getActiveRoute(routes)}
                                        secondary={getActiveNavbar(routes)}
                                        message={getActiveNavbarText(routes)}
                                        fixed={fixed}
                                        user={user}
                                        {...rest}
                                    />
                                </Box>
                            </Portal>

                            {getRoute() ? (
                                <Box mx='auto' p={{base: '20px', md: '30px'}} pe='20px' minH='100vh' pt='50px'>
                                    <Switch>
                                        {getRoutes(routes)}

                                        <Route path={'/admin/user/form/:userID'} component={CompanyUser} key={11}/>
                                        <Route path={'/admin/user/new'} component={CompanyNewUserForm} key={12}/>
                                        <Route path={'/admin/topic/form/:topicID'} component={CompanyTopic} key={13}/>
                                        <Route path={'/admin/topic/new'} component={CompanyNewTopicForm} key={14}/>
                                        <Route path={'/admin/newsitem/form/:newsID'} component={NewsSource} key={15}/>
                                        <Route path={'/admin/newsitem/new'} component={NewsSourceNewForm} key={16}/>

                                        <Redirect from='/' to='/admin/default'/>
                                        <Redirect from='/#' to='/admin/default'/>
                                        <Redirect from='/auth' to='/auth/sign-in/default'/>
                                    </Switch>
                                </Box>
                            ) : null}
                            <Box>
                                <Footer/>
                            </Box>
                        </Box>
                    </SidebarContext.Provider>
                </Box>
            )}
        </Authenticator>
    );
}

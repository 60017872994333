import {
    Box,
    Button,
    Center,
    Flex,
    Icon,
    SkeletonText,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Textarea,
    Th,
    Thead,
    Tr,
    useColorModeValue
} from '@chakra-ui/react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState
} from '@tanstack/react-table';

// Custom components
import * as React from 'react';
import {useEffect} from 'react';
import {MdCached, MdCancel, MdCheckCircle, MdOutlineEditNote, MdOutlinePersonAddAlt1} from "react-icons/md";
import useAxiosFunction from "../../../../hooks/useAxiosFunction";
import axios, {dateTimeWithShift} from "../../../../api/BackendRest";
import {DateTime} from "luxon";
import {NavLink} from "react-router-dom";
import useCognitoGroups from "../../../../hooks/cognitoGroupsFunction";
import SimpleDatePicker from "../../../../components/datepicker/SimpleDatePicker";
// Assets


type RowObj = {
    id: number,
    topicName: string,
    article: number,
    read: boolean,
    createDate: string,
    modifiedDate: string,
    rowText: string,
    type: string,
    version: number,
    status: string
};

const defaultSortingSate = [{
    "id": "id",
    "desc": true
}]

const defaultVisibilityState =
    {
        "version": false
    }

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function NewsSourceTable() {

    const [sorting, setSorting] = React.useState<SortingState>(defaultSortingSate);
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(defaultVisibilityState);
    const [from, setFrom] = React.useState<DateTime>(dateTimeWithShift());

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'});

    const [response, setResponse, error, , loading, axiosFetch] = useAxiosFunction([]);
    const [isAdmin, isConsoleAdmin,] = useCognitoGroups(false);

    const getData = (from: DateTime = dateTimeWithShift()) => {
        if (response.length)
            setResponse([]);
        axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: `/news/source?from=${from.toISODate()}`
        });
        setFrom(from);
        setSorting(defaultSortingSate);
    }

    useEffect(() => {
        isConsoleAdmin();
        getData();
    }, [])

    let defaultData = response as [];
    const columns = [
        columnHelper.display({
            id: 'rowNumber',
            cell: ({row, table}) => (
                <Flex align='center'>
                    <Text
                        color={textColorSecondary}
                        fontSize='sm'
                        fontWeight='600'>
                        {(table?.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    ID
                </Text>
            ),
            cell: (info) => (
                <Flex align='center'>
                    <Text
                        color={textColorSecondary}
                        fontSize='sm'
                        fontWeight='600'>
                        {info.getValue()}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('article', {
            id: 'article',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    ARTICLE
                </Text>
            ),
            cell: (info) => (
                <Flex align='center'>
                    <Text
                        color={textColorSecondary}
                        fontSize='sm'
                        fontWeight='600'>
                        {info.getValue()}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('topicName', {
            id: 'topicName',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    TOPIC
                </Text>
            ),
            cell: (info) => (
                <Text
                    color={textColor}
                    fontSize='sm'
                    fontWeight='500'>
                    {info.getValue()}
                </Text>
            )
        }),
        columnHelper.accessor('createDate', {
            id: 'createDate',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    CREATED
                </Text>
            ),
            cell: (info) => (
                <Box bg={"transparent"} alignContent={"flex-start"} justifyContent={"flex-start"}>
                    <Text
                        color={textColor}
                        fontSize='sm'
                        fontWeight='500'>
                        {info.getValue() && DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_SHORT)}
                    </Text>
                    <Text
                        color={textColor}
                        fontSize='sm'
                        fontWeight='500'>
                        {info.getValue() && DateTime.fromISO(info.getValue()).toLocaleString(DateTime.TIME_24_SIMPLE)}
                    </Text>
                </Box>
            )
        }),
        columnHelper.accessor('modifiedDate', {
            id: 'modifiedDate',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    MODIFIED
                </Text>
            ),
            cell: (info) => (
                <Box bg={"transparent"} alignContent={"flex-start"} justifyContent={"flex-start"}>
                    <Text
                        color={textColor}
                        fontSize='sm'
                        fontWeight='500'>
                        {info.getValue() && DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_SHORT)}
                    </Text>
                    <Text
                        color={textColor}
                        fontSize='sm'
                        fontWeight='500'>
                        {info.getValue() && DateTime.fromISO(info.getValue()).toLocaleString(DateTime.TIME_24_SIMPLE)}
                    </Text>
                </Box>
            )
        }),
        columnHelper.accessor('read', {
            id: 'read',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    PROCESSED
                </Text>
            ),
            cell: (info) => (
                <Icon
                    w='20px'
                    h='20px'
                    alignContent={"center"}
                    alignItems={"center"}
                    color={
                        info.getValue() ? (
                            'green.500'
                        ) : 'red.500'
                    }
                    as={
                        info.getValue() ? (
                            MdCheckCircle
                        ) : MdCancel
                    }
                />
            )
        }),
        columnHelper.accessor('type', {
            id: 'type',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    TYPE
                </Text>
            ),
            cell: (info) => (
                <Text
                    color={textColor}
                    fontSize='sm'
                    fontWeight='500'>
                    {info.getValue()}
                </Text>
            )
        }),
        columnHelper.accessor('rowText', {
            id: 'rowText',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    RAW TEXT
                </Text>
            ),
            cell: (info) => (
                <Textarea
                    size={"lg"}
                    minHeight={128}
                    color={textColor}
                    fontSize='sm' value={info.getValue()} readOnly>
                </Textarea>
            )
        }),
        columnHelper.display({
            id: "modify",
            cell: ({row}) => (
                <Button
                    alignItems='center'
                    justifyContent='center'
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}
                    w='37px'
                    h='37px'
                    lineHeight='100%'
                    borderRadius='10px'>
                    <NavLink exact to={`/admin/newsitem/form/${row.original.id}`}>
                        <Icon as={MdOutlineEditNote} color={iconColor} w='24px' h='24px'/>
                    </NavLink>
                </Button>
            )
        })
    ];
    const data = [...defaultData];
    const table = useReactTable({
        data,
        columns,
        state: {
            columnVisibility,
            sorting,
        },
        enableColumnResizing: true,
        onColumnVisibilityChange: setColumnVisibility,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    return (
        <Flex
            direction='column'
            w='100%'
            overflowX={{sm: "scroll", lg: "hidden"}}>
            <Flex
                align={{sm: "flex-start", lg: "center"}}
                justify='space-between'
                w='100%'
                px='22px'
                pb='20px'
                mb='10px'
                boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
                <Text color={textColor} fontSize='xl' fontWeight='600'>
                    Company News Source
                </Text>
                <Flex>
                    <Stack direction={['column', 'row']}>
                        <SimpleDatePicker dataFunc={getData} from={from} setFrom={setFrom}/>
                        <Button
                            alignItems='center'
                            justifyContent='center'
                            bg={bgButton}
                            _hover={bgHover}
                            _focus={bgFocus}
                            _active={bgFocus}
                            w='37px'
                            h='37px'
                            lineHeight='100%'
                            disabled={!isAdmin}
                            borderRadius='10px'>
                            {isAdmin && <NavLink exact to={`/admin/newsitem/new`}>
                                <Icon as={MdOutlinePersonAddAlt1} color={iconColor} w='24px' h='24px'/>
                            </NavLink>
                            }
                            {!isAdmin &&
                                <Icon as={MdOutlinePersonAddAlt1} color={iconColor} w='24px' h='24px'/>
                            }
                        </Button>

                        <Button
                            alignItems='center'
                            justifyContent='center'
                            bg={bgButton}
                            _hover={bgHover}
                            _focus={bgFocus}
                            _active={bgFocus}
                            w='37px'
                            h='37px'
                            lineHeight='100%'
                            borderRadius='10px'
                            isLoading={loading}
                            onClick={() => getData()}>
                            {!loading && <Icon as={MdCached} color={iconColor} w='24px' h='24px'/>}
                        </Button>
                    </Stack>
                </Flex>
            </Flex>
            <Flex direction='column' w='100%' overflowX={'auto'}>
                <Table variant='striped' color='gray.500' mt="12px" overflowX={'auto'} minW={'1em'} maxWidth={'none'}>
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <Th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            pe='10px'
                                            borderColor={borderColor}
                                            cursor='pointer'
                                            onClick={header.column.getToggleSortingHandler()}>
                                            <Flex
                                                justifyContent='space-between'
                                                align='center'
                                                fontSize={{sm: '10px', lg: '12px'}}
                                                color='gray.400'>
                                                {flexRender(header.column.columnDef.header, header.getContext())}{{
                                                asc: '',
                                                desc: '',
                                            }[header.column.getIsSorted() as string] ?? null}
                                            </Flex>
                                        </Th>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row) => {
                            return (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <Td
                                                key={cell.id}
                                                fontSize={{sm: '10px', lg: '12px'}}
                                                borderColor='transparent'>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
                {loading && !response?.length &&
                    <Box padding='6' boxShadow='lg'>
                        <SkeletonText mt='4' noOfLines={4} spacing='4'/>
                    </Box>}
                {!loading && error &&
                    <Center padding='6' boxShadow='lg' justifyContent={"center"} color={textColor}>
                        <Box as='span' fontWeight='bold' fontSize='lg'>
                            {error}
                        </Box>
                    </Center>
                }
            </Flex>
        </Flex>
    );
}
import {useState} from "react";
import {Auth} from "aws-amplify";
import {ConsoleAdmins} from "../api/Constants";

export const isConsoleAdminGroup = async (): Promise<boolean> => isInGroup({groups: ConsoleAdmins});

export const isInGroup = async (configObj: {
    groups: String[]
}): Promise<boolean> => {

    const {
        groups = []
    } = configObj;

    const token = (await Auth.currentSession())?.getAccessToken();
    const cognitoGroups = token?.payload["cognito:groups"] || [];
    return groups.every(v => cognitoGroups.includes(v));
}

const useCognitoGroups = (defaultValue: boolean): [boolean, () => Promise<void>, (configObj: any) => Promise<void>] => {

    const [present, setPresent] = useState<boolean>(defaultValue);

    const isConsoleAdminGroup = (): Promise<void> => isSomeCognitoGroup({groups: ConsoleAdmins})

    const isSomeCognitoGroup = async (configObj: {
        groups: String[]
    }): Promise<void> => {

        const {
            groups = []
        } = configObj;
        setPresent(await isInGroup({groups: groups}));
    }

    return [present, isConsoleAdminGroup, isSomeCognitoGroup];
}

export default useCognitoGroups
import {Box} from '@chakra-ui/react';

import React from "react";

import Card from "../../../components/card/Card";
import CompanyTopicsTable from './components/CompanyTopicsTable';

export default function CompanyTopics() {
    return (
        <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
            <Card>
                <CompanyTopicsTable/>
            </Card>
        </Box>
    );
}

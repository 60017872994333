type RowObj = {
    name: string[];
    time: number;
    podcasts: number;
    rating: number;
};

const tableColumnsTopListeners: RowObj[] = [
    {
        name: ["yafit.mizrahi", "https://images.unsplash.com/photo-1506863530036-1efeddceb993?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2244&q=80"],
        time: 9821,
        podcasts: 97,
        rating: 97
    },
    {
        name: ["john.kelly", "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1780&q=80"],
        time: 7032,
        podcasts: 87,
        rating: 87
    },
    {
        name: ["andreea.chen", "https://images.unsplash.com/photo-1573766064535-6d5d4e62bf9d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1315&q=80"],
        time: 5204,
        podcasts: 82,
        rating: 82
    },
    {
        name: ["abraham.cohen", "https://images.unsplash.com/photo-1628157588553-5eeea00af15c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1780&q=80"],
        time: 4309,
        podcasts: 68,
        rating: 68
    },
    {
        name: ["mina.rad", "https://images.unsplash.com/photo-1672773027671-514295f5ead3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3387&q=80"],
        time: 3871,
        podcasts: 55,
        rating: 55
    }
];

export default tableColumnsTopListeners;

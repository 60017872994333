// Chakra imports
import { SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import Information from 'views/admin/profile/components/Information';

// Assets
export default function GeneralInformation(props: { [x: string]: any }) {
	const { ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
	return (
		<Card mb={{ base: '0px', '2xl': '20px' }} {...rest}>
			<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
				Company General Information
			</Text>
			<Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
                EnlightMe offers AI-generated, personalized podcasts that can be consumed during employees’ idle time, like daily commutes.
                These podcasts blend external and internal news with essential company messages, ensuring information dissemination and active alignment with the company’s objectives.
			</Text>
			<SimpleGrid columns={2} gap='20px'>
				<Information boxShadow={cardShadow} title='E-Mail' value='info@enlightme.ai' />
				<Information boxShadow={cardShadow} title='Phone' value='+972 50 300 3075' />
				<Information boxShadow={cardShadow} title='Organization' value='GenAiServices LLC' />
				<Information boxShadow={cardShadow} title='Onborded' value='20 July 2023' />
			</SimpleGrid>
		</Card>
	);
}

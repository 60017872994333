// Chakra imports
// Custom components
import Card from 'components/card/Card';
import {assessPieChartData, listenTimePieChartOptions} from 'variables/charts';
import RadialChart from "../../../../components/charts/RadialChart";
import {Box, Flex, Text, useColorModeValue} from "@chakra-ui/react";
import React from "react";

export default function Conversion(props: { [x: string]: any }) {
    const {...rest} = props;

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    return (
        <Card p='20px' alignItems='center' flexDirection='column' w='100%'  {...rest}>
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text me='auto' color={textColor} fontSize='medium' fontWeight='500' lineHeight='100%'>
                    Listened Hours Progress
                </Text>
            </Flex>
            <Box minH='300px' minW='100%' mt='auto'>
                <RadialChart h='100%' w='100%' chartData={assessPieChartData} chartOptions={listenTimePieChartOptions}/>
            </Box>
        </Card>
    );
}

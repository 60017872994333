// Chakra imports
import {Box, SimpleGrid, Text, useColorModeValue} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import Information from 'views/admin/profile/components/Information';
import {DateTime} from "luxon";
import {STATUS_ACTIVE} from "../../../../api/Constants";
import * as React from "react";

// Assets
export default function CompanyTopicGeneralInformation(props: {
                                                           topic: any,
                                                           [x: string]: any
                                                       }
) {
    const {
        topic,
        ...rest
    } = props;

    let defaultValue = "---";
// Chakra Color Mode
    const bg = useColorModeValue('white', 'navy.700');
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'gray.400';
    const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
    return (
        <Box mb={{base: '0px', '2xl': '20px'}}>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 5, '2xl': 5 }} gap='20px' mb='20px'>
                <Card bg={bg} boxShadow={cardShadow}>
                    <Box>
                        <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                            Status
                        </Text>
                        {topic?.status && <Text fontWeight='500' fontSize='md'
                                                color={topic?.status?.toUpperCase() === STATUS_ACTIVE ? ('green') : 'red'}>
                            {topic?.status}
                        </Text>}
                        {!topic?.status && <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                            UNKNOWN
                        </Text>}
                    </Box>
                </Card>
                <Information boxShadow={cardShadow} title='Type' value={topic?.type ? topic?.type : defaultValue}/>
                <Information boxShadow={cardShadow} title='Topic ID/Version'
                             value={topic?.id ? topic?.id + " / " + topic?.version : defaultValue}/>
                <Information boxShadow={cardShadow} title='Created Date'
                             value={topic?.createDate ? DateTime.fromISO(topic?.createDate).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : defaultValue}/>
                <Information boxShadow={cardShadow} title='Modified Date'
                             value={topic?.modifiedDate ? DateTime.fromISO(topic?.modifiedDate).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : defaultValue}/>
            </SimpleGrid>
        </Box>
    );
}

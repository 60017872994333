// Daily Traffic Dashboards Default

export const barChartDataDailyTraffic = [
	{
		name: 'Daily Traffic',
		data: [ 20, 30, 40, 20, 45, 50, 30 ]
	}
];

export const barChartOptionsDailyTraffic: any = {
	chart: {
		toolbar: {
			show: false
		}
	},
	tooltip: {
		style: {
			fontSize: '12px',
			fontFamily: undefined
		},
		onDatasetHover: {
			style: {
				fontSize: '12px',
				fontFamily: undefined
			}
		},
		theme: 'dark'
	},
	xaxis: {
		categories: [ '00', '04', '08', '12', '14', '16', '18' ],
		show: false,
		labels: {
			show: true,
			style: {
				colors: '#A3AED0',
				fontSize: '14px',
				fontWeight: '500'
			}
		},
		axisBorder: {
			show: false
		},
		axisTicks: {
			show: false
		}
	},
	yaxis: {
		show: false,
		color: 'black',
		labels: {
			show: true,
			style: {
				colors: '#CBD5E0',
				fontSize: '14px'
			}
		}
	},
	grid: {
		show: false,
		strokeDashArray: 5,
		yaxis: {
			lines: {
				show: true
			}
		},
		xaxis: {
			lines: {
				show: false
			}
		}
	},
	fill: {
		type: 'gradient',
		gradient: {
			type: 'vertical',
			shadeIntensity: 1,
			opacityFrom: 0.7,
			opacityTo: 0.9,
			colorStops: [
				[
					{
						offset: 0,
						color: '#4318FF',
						opacity: 1
					},
					{
						offset: 100,
						color: 'rgba(67, 24, 255, 1)',
						opacity: 0.28
					}
				]
			]
		}
	},
	dataLabels: {
		enabled: false
	},
	plotOptions: {
		bar: {
			borderRadius: 10,
			columnWidth: '40px'
		}
	}
};

export const barChartDataUserActivity = [
	{
		name: 'PRODUCT A',
		data: [ 70, 55, 41, 67, 22, 43 ]
	},
	{
		name: 'PRODUCT B',
		data: [ 90, 70, 60, 50, 80, 90 ]
	}
];

// User Activity

export const barChartOptionsUserActivity = {
	chart: {
		stacked: true,
		toolbar: {
			show: false
		}
	},
	tooltip: {
		style: {
			fontSize: '12px'
		},
		onDatasetHover: {
			style: {
				fontSize: '12px'
			}
		},
		theme: 'dark'
	},
	xaxis: {
		categories: [ 'S', 'M', 'T', 'W', 'T', 'F' ],
		show: false,
		labels: {
			show: true,
			style: {
				colors: '#A3AED0',
				fontSize: '14px',
				fontWeight: '500'
			}
		},
		axisBorder: {
			show: false
		},
		axisTicks: {
			show: false
		}
	},
	yaxis: {
		show: true,
		color: 'black',
		labels: {
			show: true,
			style: {
				colors: '#A3AED0',
				fontSize: '14px',
				fontWeight: '500'
			}
		}
	},

	grid: {
		borderColor: 'rgba(163, 174, 208, 0.3)',
		show: true,
		yaxis: {
			lines: {
				show: true,
				opacity: 0.5
			}
		},
		row: {
			opacity: 0.5
		},
		xaxis: {
			lines: {
				show: false
			}
		}
	},
	fill: {
		type: 'solid',
		colors: [ '#5E37FF', '#6AD2FF' ]
	},
	legend: {
		show: false
	},
	colors: [ '#5E37FF', '#6AD2FF' ],
	dataLabels: {
		enabled: false
	},
	plotOptions: {
		bar: {
			borderRadius: 10,
			columnWidth: '30px'
		}
	}
};
// Consumption Users Reports

export const barChartDataConsumption = [
	{
		name: 'PRODUCT A',
		data: [ 400, 370, 330, 390, 320, 350, 360, 320, 380 ]
	},
	{
		name: 'PRODUCT B',
		data: [ 400, 370, 330, 390, 320, 350, 360, 320, 380 ]
	},
	{
		name: 'PRODUCT C',
		data: [ 400, 370, 330, 390, 320, 350, 360, 320, 380 ]
	}
];

export const barChartPodcastTopicsCreated = [
    {
        name: 'Topics Created',
        data: [ 20, 35, 56, 78, 101, 130, 111, 87, 65, 40, 73, 96 ]
    }
];

export const barChartOptionsConsumption: any = {
	chart: {
		stacked: true,
		toolbar: {
			show: false
		},
        dropShadow: {
            enabled: false
        }
	},
	tooltip: {
		style: {
			fontSize: '12px',
			fontFamily: undefined
		},
		onDatasetHover: {
			style: {
				fontSize: '12px',
				fontFamily: undefined
			}
		},
		theme: 'dark'
	},
	xaxis: {
		categories: [ 'JAN', 'FEB', "MAR", "APR", "MAY", "JUN", "JUL", "AUG", 'SEP', 'OCT', 'NOV', 'DEC' ],
		show: true,
		labels: {
			show: true,
			style: {
				colors: '#A3AED0',
				fontSize: '10px',
				fontWeight: '500'
			}
		},
		axisBorder: {
			show: true
		},
		axisTicks: {
			show: true
		}
	},
	yaxis: {
		show: true,
		color: 'black',
		labels: {
			show: true,
			style: {
				colors: '#A3AED0',
				fontSize: '12px',
				fontWeight: '500'
			}
		}
	},

	grid: {
		borderColor: 'rgba(163, 174, 208, 0.3)',
		show: false,
		yaxis: {
			lines: {
				show: false,
				opacity: 0.5
			}
		},
		row: {
			opacity: 0.5
		},
		xaxis: {
			lines: {
				show: true
			}
		}
	},
	fill: {
		type: 'solid',
		colors: [ '#39B8FF', '#E1E9F8', '#5E37FF' ]
	},
	legend: {
		show: true,
        enabled: true
	},
	colors: [ '#39B8FF', '#E1E9F8', '#5E37FF' ],
	dataLabels: {
		enabled: true
	},
	plotOptions: {
		bar: {
			borderRadius: 10,
			columnWidth: '24px'
		}
	}
};

export const pieChartOptions: any = {
	labels: [ 'Your files', 'System', 'Empty' ],
	colors: [ '#4318FF', '#6AD2FF', '#EFF4FB' ],
	chart: {
		width: '50px'
	},
	states: {
		hover: {
			filter: {
				type: 'none'
			}
		}
	},
	legend: {
		show: false
	},
	dataLabels: {
		enabled: false
	},
	hover: { mode: null },
	plotOptions: {
		donut: {
			expandOnClick: false,
			donut: {
				labels: {
					show: false
				}
			}
		}
	},
	fill: {
		colors: [ '#4318FF', '#6AD2FF', '#EFF4FB' ]
	},
	tooltip: {
		enabled: true,
		theme: 'dark'
	}
};

export const assessPieChartOptions: any = {
    //labels: [ '⭐', '⭐⭐', '⭐⭐⭐', "⭐⭐⭐⭐", "⭐⭐⭐⭐⭐" ],
    labels: [ 'Likes 👍', 'Dislikes 👎'],
    colors: [ '#4318FF', '#4318FF' ],
    states: {
        hover: {
            filter: {
                type: 'none'
            }
        }
    },
    legend: {
        show: false
    },
    dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        style: {
            fontSize: '16px'
        }
    },
    hover: { mode: null },
    plotOptions: {
        donut: {
            expandOnClick: false,
            scale: 1,
            hollow: {
                margin: 25,
                size: "70%"
            }
        }
    },
    fill: {
        colors: [ '#39B8FF', '#E1E9F8' ],
    },
    tooltip: {
        enabled: true,
        theme: 'dark'
    }
};

export const assessedPieChartData = [ 83, 17 ];

export const assessPieChartData = [ 67 ];
export const pieChartData = [ 63, 25, 12 ];

export const listenTimePieChartOptions: any = {

    plotOptions: {
        radialBar: {
            hollow: {
                margin: 15,
                size: "70%"
            },

            dataLabels: {
                showOn: "always",
                name: {
                    offsetY: -10,
                    show: true,
                    color: '#A3AED0',
                    fontSize: '16px'
                },
                value: {
                    color: '#A3AED0',
                    fontSize: "30px",
                    show: true
                }
            }
        }
    },

    stroke: {
        lineCap: "round",
    },
    labels: ["Hours"]
};

// Total Spent Default
export const lineChartDataTotalSpent = [
    {
        name: 'Generated Podcasts',
        data: [ 50, 64, 48, 66, 49, 68, 50, 64, 48, 66, 49, 68 ]
    },
    {
        name: 'Listened Time (hours)',
        data: [ 30, 40, 24, 46, 20, 46, 30, 40, 24, 46, 20, 46 ]
    }
];

export const lineChartPodcastsTotalGenerated = [
	{
		name: 'Podcasts Released',
		data: [ 50, 64, 48, 66, 49, 68, 50, 64, 48, 66, 49, 68 ]
	},
	{
		name: 'Podcasts Listened',
		data: [ 30, 40, 24, 46, 20, 46, 30, 40, 24, 46, 20, 46 ]
	}
];

export const lineChartOptionsTotalSpent: any = {
	chart: {
		toolbar: {
			show: false
		},
		dropShadow: {
			enabled: true,
			top: 13,
			left: 0,
			blur: 10,
			opacity: 0.1,
			color: '#4318FF'
		}
	},
	colors: [ '#4318FF', '#39B8FF' ],
	markers: {
		size: 0,
		colors: 'white',
		strokeColors: '#7551FF',
		strokeWidth: 3,
		strokeOpacity: 0.9,
		strokeDashArray: 0,
		fillOpacity: 1,
		discrete: [],
		shape: 'circle',
		radius: 2,
		offsetX: 0,
		offsetY: 0,
		showNullDataPoints: true
	},
	tooltip: {
		theme: 'dark'
	},
	dataLabels: {
		enabled: false
	},
	stroke: {
		curve: 'smooth',
		type: 'line'
	},
	xaxis: {
		type: 'numeric',
		categories: [ 'JAN', 'FEB', "MAR", "APR", "MAY", "JUN", "JUL", "AUG", 'SEP', 'OCT', 'NOV', 'DEC' ],
		labels: {
			style: {
				colors: '#A3AED0',
				fontSize: '12px',
				fontWeight: '500'
			}
		},
		axisBorder: {
			show: true
		},
		axisTicks: {
			show: true
		}
	},
    yaxis: {
        show: true,
        color: 'black',
        labels: {
            show: true,
            style: {
                colors: '#A3AED0',
                fontSize: '12px',
                fontWeight: '500'
            }
        }
    },
	legend: {
		show: false
	},
	grid: {
		show: false,
		column: {
			color: [ '#7551FF', '#39B8FF' ],
			opacity: 0.5
		}
	},
	color: [ '#7551FF', '#39B8FF' ]
};

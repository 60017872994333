// Chakra imports
import Card from 'components/card/Card';
// Custom components
import BarChart from 'components/charts/BarChart';
import React from 'react';
import {
    barChartOptionsConsumption,
    barChartPodcastTopicsCreated, lineChartOptionsTotalSpent,
    lineChartPodcastsTotalGenerated
} from 'variables/charts';
import {Box, Flex, Text, useColorModeValue} from "@chakra-ui/react";
import LineChart from "../../../../components/charts/LineChart";

export default function WeeklyRevenue(props: { [x: string]: any }) {
    const {...rest} = props;
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    return (
        <Card justifyContent='center' alignItems='center' flexDirection='column' w='100%' mb='0px'  {...rest}>
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text me='auto' color={textColor} fontSize='medium' fontWeight='500' lineHeight='100%'>
                    Topics Created
                </Text>
            </Flex>
            <Box minH='350px' minW='100%' mt='auto'>
                <BarChart chartData={barChartPodcastTopicsCreated} chartOptions={barChartOptionsConsumption}/>
            </Box>
        </Card>
    );
}

import {Box, useToast} from "@chakra-ui/react";
import React, {useEffect} from "react";
import useAxiosFunction from "../../../../hooks/useAxiosFunction";
import {defaultNewSourceItem, TOAST_DURATION_MS} from "../../../../api/Constants";
import {useHistory} from "react-router-dom";
import {newTopicSuccessMessage, validateField, ValidationResult} from "../../common/Validation";
import axios from "../../../../api/BackendRest";
import NewsSourceDetails from "./NewsSourceDetails";
import useCognitoGroups from "../../../../hooks/cognitoGroupsFunction";


const NewsSourceNewForm: React.FC = (): JSX.Element => {
    const [response, setResponse, error, setError, loading, axiosFetch] = useAxiosFunction(defaultNewSourceItem);
    const [isAdmin, isConsoleAdmin,] = useCognitoGroups(false);
    const history = useHistory();
    const toast = useToast()

    useEffect(() => {
        isConsoleAdmin();
    }, [])

    useEffect(() => {
        if (response?.id) {
            toast({
                title: newTopicSuccessMessage(response),
                duration: TOAST_DURATION_MS,
                status: 'success',
                isClosable: true,
            });
            history.push('/admin/news');
        }
    }, [response?.id])

    const clear = () => {
        setResponse(defaultNewSourceItem)
        setError(null)
    }

    const deleteTopic = () => {
        history.push('/admin/news')
    }

    const validate = (name: string, value: string, validation: (name: string, value: string) => ValidationResult): boolean => {
        const result = validation(name, value);
        if (!result.isValid)
            toast({
                title: result.message,
                status: 'error',
                isClosable: true,
            })
        return result.isValid;
    }

    const saveTopic = () => {
        if (validate("Raw Text", response.rowText, validateField)
            && validate("Topic Name", response.topicName, validateField)
            && validate("Type", response.type, validateField))
            axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/news/source',
                body: response
            });
    }

    return (
        <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
            {/* Main Fields */}
            <NewsSourceDetails dataFunc={clear}
                               saveFunc={saveTopic}
                               deleteFunc={deleteTopic}
                               canModify={isAdmin}
                               loading={loading}
                               error={error}
                               topic={response}
                               allowControl={true}
                               setResponse={setResponse}/>
        </Box>
    );
}

export default NewsSourceNewForm
import {Box} from '@chakra-ui/react';

import React from "react";

import Card from "../../../components/card/Card";
import CompanyPodcastsTable from './components/CompanyPodcastsTable';

export default function CompanyPodcasts() {
    return (
        <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
            <Card>
                <CompanyPodcastsTable/>
            </Card>
        </Box>
    );
}
